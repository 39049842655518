import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL2
const API_URL2 = process.env.REACT_APP_API_URL



//get salesinvoices2
export function getAlltransactions(member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) {

  return axios.get(`${API_URL}/getalltransactions`, { params: {member_id, start_limit, paginationsize, itemto_order, desorasc, search_item}})
}


export function getAlltransactions2(member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) {

  return axios.get(`${API_URL}/getalltransactions`, { params: {member_id, start_limit, paginationsize, itemto_order, desorasc, search_item}})
}

export function getAlltransactions3(member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) {

  return axios.get(`${API_URL}/getalltransactions`, { params: {member_id, start_limit, paginationsize, itemto_order, desorasc, search_item}})
}

export function getAlltransactions4(member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) {

  return axios.get(`${API_URL}/getalltransactions`, { params: {member_id, start_limit, paginationsize, itemto_order, desorasc, search_item}})
}


//get userroles2
export function getUserroles2() {

  return axios.get(`${API_URL2}/userroles2`)
}



//get userrole
export function getUserrole(user_role_id: number) {

  return axios.get(`${API_URL2}/userroles`, { params: {user_role_id}})
}
//get userrole
export function updateBiodata (biodatatoupdate: any) {

  return axios.put(`${API_URL2}/user_update`, biodatatoupdate)
}

//getbio data
export function getBiodata(VAT_No: number) {

  return axios.get(`${API_URL}/getbiodata`, { params: {VAT_No}})
}

export function updateBiodata2 (biodatatoupdate2: any) {
  return axios.put(`${API_URL}/biodata_update`, biodatatoupdate2)
}

export function getAllmemberdata(start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string, user_role_id: number, user_designation: number) {

  return axios.get(`${API_URL}/getallmemberdata`, { params: {start_limit, paginationsize, itemto_order, desorasc, search_item, user_role_id, user_designation}})
}
