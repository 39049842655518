/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { useUserroles } from '../../../../Transactionmanager/contextProviders/Transactionsprovider'
import axios from 'axios'
import { useBiodataContext } from '../../../../Transactionmanager/contextProviders/BiodataContext'
import { Changepasswordrequest } from '../../../../Transactionmanager/globalmodal/Changepasswordrequest'
import { Updatepassword } from '../../../../Transactionmanager/globalmodal/Updatepassword'


const API_URL = process.env.REACT_APP_API_URL
const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()

  const {modalUpdatepassword, setModalUpdatepassword, modalpasswordchange,  setModalPasswordchange} = useBiodataContext();


  const {
    isLoading: userrolesloading,
    isSuccess: userrolessuccess,
    data: userrolesdata,
    
  } = useUserroles(
    Number(currentUser?.user_role_id)
    
  )

 

  return (
    <>
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/avatar.svg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.second_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{userrolessuccess ? userrolesdata?.data.role_name : ""}</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-8 mt-1'>
              {currentUser?.email}
            </a>

            
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'biodata/biodatainfo'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text text-primary' onClick={()=>{
            setModalUpdatepassword(true)
          }}>Change Password</span>
         
        </a>
      </div>

     
      <div className='separator my-2'></div>

     

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  
    <Updatepassword
        show={modalUpdatepassword}
        passworditemtoupdate={currentUser}
        handleClose={function (): void {
          setModalUpdatepassword(false)
        }}
      
      />
     <Changepasswordrequest
        show={modalpasswordchange}
      
        handleClose={function (): void {
          setModalPasswordchange(false)
        }}
     
      />
</>
  )
}

export {HeaderUserMenu}
