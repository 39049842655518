/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/home'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Communication</span>
        </div>
      </div>
    
      <AsideMenuItemWithSub
        to='#'
        title='Notifications'
        icon='/media/icons/duotune/communication/com002.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/norights' title='Messages' hasBullet={true} />
        </AsideMenuItemWithSub>

      {(Number(currentUser?.user_role_id) === 3 && (Number(currentUser?.user_designation_id) === 3 || Number(currentUser?.user_designation_id) === 4))
      &&(
        
      <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administration</span>
        </div>
      </div>
    
      <AsideMenuItemWithSub
        to='#'
        title='Members'
        icon='/media/icons/duotune/communication/com014.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/members/viewmembers' title='View Members' hasBullet={true} />
      
      </AsideMenuItemWithSub>
      </>
      )
     
       }
     
      
      
  
     
    </>
  )
}
