export const nationalityOptions = [
    {value: 'AFGHAN', label: 'AFGHAN'},
    {value: 'ALBANIAN', label: 'ALBANIAN'},
    {value: 'ALGERIAN', label: 'ALGERIAN'},
    {value: 'AMERICAN', label: 'AMERICAN'},
    {value: 'ANDORRAN', label: 'ANDORRAN'},
    {value: 'ANGOLAN', label: 'ANGOLAN'},
    {value: 'ANTIGUAN', label: 'ANTIGUAN'},
    {value: 'ARGENTINE', label: 'ARGENTINE'},
    {value: 'ARMENIAN', label: 'ARMENIAN'},
    {value: 'AUSTRALIAN', label: 'AUSTRALIAN'},
    {value: 'AUSTRIAN', label: 'AUSTRIAN'},
    {value: 'AZERBAIJANI', label: 'AZERBAIJANI'},
    {value: 'BAHAMIAN', label: 'BAHAMIAN'},
    {value: 'BAHRAINI', label: 'BAHRAINI'},
    {value: 'BANGLADESHI', label: 'BANGLADESHI'},
    {value: 'BARBADIAN', label: 'BARBADIAN'},
    {value: 'BELARUSIAN', label: 'BELARUSIAN'},
    {value: 'BELGIAN', label: 'BELGIAN'},
    {value: 'BELIZEAN', label: 'BELIZEAN'},
    {value: 'BENINESE', label: 'BENINESE'},
    {value: 'BHUTANESE', label: 'BHUTANESE'},
    {value: 'BOLIVIAN', label: 'BOLIVIAN'},
    {value: 'BOSNIAN', label: 'BOSNIAN'},
    {value: 'BOTSWANAN', label: 'BOTSWANAN'},
    {value: 'BRAZILIAN', label: 'BRAZILIAN'},
    {value: 'BRITISH', label: 'BRITISH'},
    {value: 'BRUNEIAN', label: 'BRUNEIAN'},
    {value: 'BULGARIAN', label: 'BULGARIAN'},
    {value: 'BURKINABE', label: 'BURKINABE'},
    {value: 'BURUNDIAN', label: 'BURUNDIAN'},
    {value: 'CABO VERDEAN', label: 'CABO VERDEAN'},
    {value: 'CAMBODIAN', label: 'CAMBODIAN'},
    {value: 'CAMEROONIAN', label: 'CAMEROONIAN'},
    {value: 'CANADIAN', label: 'CANADIAN'},
    {value: 'CENTRAL AFRICAN', label: 'CENTRAL AFRICAN'},
    {value: 'CHADIAN', label: 'CHADIAN'},
    {value: 'CHILEAN', label: 'CHILEAN'},
    {value: 'CHINESE', label: 'CHINESE'},
    {value: 'COLOMBIAN', label: 'COLOMBIAN'},
    {value: 'COMORAN', label: 'COMORAN'},
    {value: 'CONGOLESE', label: 'CONGOLESE'},
    {value: 'COSTA RICAN', label: 'COSTA RICAN'},
    {value: 'CROATIAN', label: 'CROATIAN'},
    {value: 'CUBAN', label: 'CUBAN'},
    {value: 'CYPRIOT', label: 'CYPRIOT'},
    {value: 'CZECH', label: 'CZECH'},
    {value: 'DANISH', label: 'DANISH'},
    {value: 'DJIBOUTIAN', label: 'DJIBOUTIAN'},
    {value: 'DOMINICAN', label: 'DOMINICAN'},
    {value: 'DUTCH', label: 'DUTCH'},
    {value: 'EAST TIMORESE', label: 'EAST TIMORESE'},
    {value: 'ECUADOREAN', label: 'ECUADOREAN'},
    {value: 'EGYPTIAN', label: 'EGYPTIAN'},
    {value: 'SALVADORAN', label: 'SALVADORAN'},
    {value: 'EQUATORIAL GUINEAN', label: 'EQUATORIAL GUINEAN'},
    {value: 'ERITREAN', label: 'ERITREAN'},
    {value: 'ESTONIAN', label: 'ESTONIAN'},
    {value: 'ESWATINI', label: 'ESWATINI'},
    {value: 'ETHIOPIAN', label: 'ETHIOPIAN'},
    {value: 'FIJIAN', label: 'FIJIAN'},
    {value: 'FINNISH', label: 'FINNISH'},
    {value: 'FRENCH', label: 'FRENCH'},
    {value: 'GABONESE', label: 'GABONESE'},
    {value: 'GAMBIAN', label: 'GAMBIAN'},
    {value: 'GEORGIAN', label: 'GEORGIAN'},
    {value: 'GERMAN', label: 'GERMAN'},
    {value: 'GHANAIAN', label: 'GHANAIAN'},
    {value: 'GREEK', label: 'GREEK'},
    {value: 'GRENADIAN', label: 'GRENADIAN'},
    {value: 'GUATEMALAN', label: 'GUATEMALAN'},
    {value: 'GUINEAN', label: 'GUINEAN'},
    {value: 'GUINEA-BISSAUAN', label: 'GUINEA-BISSAUAN'},
    {value: 'GUYANESE', label: 'GUYANESE'},
    {value: 'HAITIAN', label: 'HAITIAN'},
    {value: 'HONDURAN', label: 'HONDURAN'},
    {value: 'HUNGARIAN', label: 'HUNGARIAN'},
    {value: 'ICELANDER', label: 'ICELANDER'},
    {value: 'INDIAN', label: 'INDIAN'},
    {value: 'INDONESIAN', label: 'INDONESIAN'},
    {value: 'IRANIAN', label: 'IRANIAN'},
    {value: 'IRAQI', label: 'IRAQI'},
    {value: 'IRISH', label: 'IRISH'},
    {value: 'ISRAELI', label: 'ISRAELI'},
    {value: 'ITALIAN', label: 'ITALIAN'},
    {value: 'IVORIAN', label: 'IVORIAN'},
    {value: 'JAMAICAN', label: 'JAMAICAN'},
    {value: 'JAPANESE', label: 'JAPANESE'},
    {value: 'JORDANIAN', label: 'JORDANIAN'},
    {value: 'KAZAKH', label: 'KAZAKH'},
    {value: 'KENYAN', label: 'KENYAN'},
    {value: 'KIRIBATI', label: 'KIRIBATI'},
    {value: 'KOSOVAR', label: 'KOSOVAR'},
    {value: 'KUWAITI', label: 'KUWAITI'},
    {value: 'KYRGYZ', label: 'KYRGYZ'},
    {value: 'LAO', label: 'LAO'},
    {value: 'LATVIAN', label: 'LATVIAN'},
    {value: 'LEBANESE', label: 'LEBANESE'},
    {value: 'LESOTHO', label: 'LESOTHO'},
    {value: 'LIBERIAN', label: 'LIBERIAN'},
    {value: 'LIBYAN', label: 'LIBYAN'},
    {value: 'LIECHTENSTEINER', label: 'LIECHTENSTEINER'},
    {value: 'LITHUANIAN', label: 'LITHUANIAN'},
    {value: 'LUXEMBOURGISH', label: 'LUXEMBOURGISH'},
    {value: 'MADAGASCAR', label: 'MADAGASCAR'},
    {value: 'MALAWIAN', label: 'MALAWIAN'},
    {value: 'MALAYSIAN', label: 'MALAYSIAN'},
    {value: 'MALDIVIAN', label: 'MALDIVIAN'},
    {value: 'MALIAN', label: 'MALIAN'},
    {value: 'MALTESE', label: 'MALTESE'},
    {value: 'MARSHALLESE', label: 'MARSHALLESE'},
    {value: 'MAURITANIAN', label: 'MAURITANIAN'},
    {value: 'MAURITIAN', label: 'MAURITIAN'},
    {value: 'MEXICAN', label: 'MEXICAN'},
    {value: 'MICRONESIAN', label: 'MICRONESIAN'},
    {value: 'MOLDOVAN', label: 'MOLDOVAN'},
    {value: 'MONACAN', label: 'MONACAN'},
    {value: 'MONGOLIAN', label: 'MONGOLIAN'},
    {value: 'MONTENEGRIN', label: 'MONTENEGRIN'},
    {value: 'MOROCCAN', label: 'MOROCCAN'},
    {value: 'MOZAMBICAN', label: 'MOZAMBICAN'},
    {value: 'NAMIBIAN', label: 'NAMIBIAN'},
    {value: 'NAURUAN', label: 'NAURUAN'},
    {value: 'NEPALESE', label: 'NEPALESE'},
    {value: 'NEW ZEALANDER', label: 'NEW ZEALANDER'},
    {value: 'NICARAGUAN', label: 'NICARAGUAN'},
    {value: 'NIGERIEN', label: 'NIGERIEN'},
    {value: 'NIGERIAN', label: 'NIGERIAN'},
    {value: 'NORTH KOREAN', label: 'NORTH KOREAN'},
    {value: 'NORTH MACEDONIAN', label: 'NORTH MACEDONIAN'},
    {value: 'NORWEGIAN', label: 'NORWEGIAN'},
    {value: 'OMANI', label: 'OMANI'},
    {value: 'PAKISTANI', label: 'PAKISTANI'},
    {value: 'PALAUAN', label: 'PALAUAN'},
    {value: 'PALESTINIAN', label: 'PALESTINIAN'},
    {value: 'PANAMANIAN', label: 'PANAMANIAN'},
    {value: 'PAPUA NEW GUINEAN', label: 'PAPUA NEW GUINEAN'},
    {value: 'PARAGUAYAN', label: 'PARAGUAYAN'},
    {value: 'PERUVIAN', label: 'PERUVIAN'},
    {value: 'FILIPINO', label: 'FILIPINO'},
    {value: 'POLISH', label: 'POLISH'},
    {value: 'PORTUGUESE', label: 'PORTUGUESE'},
    {value: 'QATARI', label: 'QATARI'},
    {value: 'ROMANIAN', label: 'ROMANIAN'},
    {value: 'RUSSIAN', label: 'RUSSIAN'},
    {value: 'RWANDAN', label: 'RWANDAN'},
    {value: 'SAINT KITTS AND NEVIS', label: 'SAINT KITTS AND NEVIS'},
    {value: 'SAINT LUCIAN', label: 'SAINT LUCIAN'},
    {value: 'SAINT VINCENTIAN', label: 'SAINT VINCENTIAN'},
    {value: 'SAMOAN', label: 'SAMOAN'},
    {value: 'SAN MARINESE', label: 'SAN MARINESE'},
    {value: 'SAO TOMEAN', label: 'SAO TOMEAN'},
    {value: 'SAUDI', label: 'SAUDI'},
    {value: 'SENEGALESE', label: 'SENEGALESE'},
    {value: 'SERBIAN', label: 'SERBIAN'},
    {value: 'SEYCHELLOIS', label: 'SEYCHELLOIS'},
    {value: 'SIERRA LEONEAN', label: 'SIERRA LEONEAN'},
    {value: 'SINGAPOREAN', label: 'SINGAPOREAN'},
    {value: 'SLOVAK', label: 'SLOVAK'},
    {value: 'SLOVENE', label: 'SLOVENE'},
    {value: 'SOLOMON ISLANDER', label: 'SOLOMON ISLANDER'},
    {value: 'SOMALI', label: 'SOMALI'},
    {value: 'SOUTH AFRICAN', label: 'SOUTH AFRICAN'},
    {value: 'SOUTH KOREAN', label: 'SOUTH KOREAN'},
    {value: 'SOUTH SUDANESE', label: 'SOUTH SUDANESE'},
    {value: 'SPANISH', label: 'SPANISH'},
    {value: 'SRI LANKAN', label: 'SRI LANKAN'},
    {value: 'SUDANESE', label: 'SUDANESE'},
    {value: 'SURINAMESE', label: 'SURINAMESE'},
    {value: 'SWAZI', label: 'SWAZI'},
    {value: 'SWEDISH', label: 'SWEDISH'},
    {value: 'SWISS', label: 'SWISS'},
    {value: 'SYRIAN', label: 'SYRIAN'},
    {value: 'TAIWANESE', label: 'TAIWANESE'},
    {value: 'TAJIK', label: 'TAJIK'},
    {value: 'TANZANIAN', label: 'TANZANIAN'},
    {value: 'THAI', label: 'THAI'},
    {value: 'TOGOLESE', label: 'TOGOLESE'},
    {value: 'TONGAN', label: 'TONGAN'},
    {value: 'TRINIDADIAN OR TOBAGONIAN', label: 'TRINIDADIAN OR TOBAGONIAN'},
    {value: 'TUNISIAN', label: 'TUNISIAN'},
    {value: 'TURKISH', label: 'TURKISH'},
    {value: 'TURKMEN', label: 'TURKMEN'},
    {value: 'TUVALUAN', label: 'TUVALUAN'},
    {value: 'UGANDAN', label: 'UGANDAN'},
    {value: 'UKRAINIAN', label: 'UKRAINIAN'},
    {value: 'URUGUAYAN', label: 'URUGUAYAN'},
    {value: 'UZBEK', label: 'UZBEK'},
    {value: 'VANUATU', label: 'VANUATU'},
    {value: 'VATICAN', label: 'VATICAN'},
    {value: 'VENEZUELAN', label: 'VENEZUELAN'},
    {value: 'VIETNAMESE', label: 'VIETNAMESE'},
    {value: 'YEMENI', label: 'YEMENI'},
    {value: 'ZAMBIAN', label: 'ZAMBIAN'},
    {value: 'ZIMBABWEAN', label: 'ZIMBABWEAN'}
];
