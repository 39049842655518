
import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'



import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { KTSVG } from '../../../_metronic/helpers'
import { useUpdatebiodata } from '../../contextProviders/Transactionsprovider'
import { useAuth } from '../../../app/modules/auth'
import { useBiodataContext } from '../../contextProviders/BiodataContext'



dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void

}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
export const Upadatesuccessful: FC<Props> = forwardRef((props, ref) => {
  
 


  return (
    <Modal
      className='modal fade'
      id='kt_modal_clientsbrand_form'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Update Successful</h3>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
          
        <div className='fs-6 alert alert-success p-5 text-dark'>Your biographical data has been successfully updated.</div>


        </div>

        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-success' onClick={props.handleClose}>
            Close
          </button>
         
        </div>
      </div>
    </Modal>
  )
})
