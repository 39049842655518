import { FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction } from 'react'
import { getAlltransactions, getUserroles2, getAlltransactions2, getAlltransactions3, getAlltransactions4, getUserrole, updateBiodata, getBiodata, updateBiodata2, getAllmemberdata} from '../apis/Loadalltabledata'
import { useAuth } from '../../app/modules/auth'
import { useQuery ,useMutation} from 'react-query'



export const useAlltransactionsquery = (member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) => useQuery(
  ['alltransactionsquery', start_limit, paginationsize],

  () => {

    return getAlltransactions(member_id, start_limit, paginationsize, itemto_order, desorasc, search_item)
  },
  {
    keepPreviousData: true,
    
  }
)



export const useAlltransactionsquery2 = (member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) => useQuery(
  ['alltransactionsquery2', start_limit, paginationsize],

  () => {

    return getAlltransactions2(member_id, start_limit, paginationsize, itemto_order, desorasc, search_item)
  },
  {
    keepPreviousData: true,
    
  }
)

export const useAlltransactionsquery3 = (member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) => useQuery(
  ['alltransactionsquery3', start_limit, paginationsize],

  () => {

    return getAlltransactions3(member_id, start_limit, paginationsize, itemto_order, desorasc, search_item)
  },
  {
    keepPreviousData: true,
    
  }
)

export const useAlltransactionsquery4 = (member_id: number, start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string) => useQuery(
  ['alltransactionsquery4', start_limit, paginationsize],

  () => {

    return getAlltransactions4(member_id, start_limit, paginationsize, itemto_order, desorasc, search_item)
  },
  {
    keepPreviousData: true,
    
  }
)


// get userroles2

export const useAlluserroles2 = () => useQuery(
  'alluserroles2',
  () => {
    return getUserroles2()
  },
  {
    keepPreviousData: true,
    
    
  }
)

// get userrole
export const useUserroles = (user_role_id: number) => useQuery(
  'userroles',
  () => {
    return getUserrole(Number(user_role_id))
  },
  {
    keepPreviousData: true,
    
    
  }
)
// get userrole

export const useUpdatebiodata = (biodataToUpdate: any) => useMutation({
  mutationFn: () => updateBiodata(biodataToUpdate)
});


// get userroles2

export const useBiodata = (VAT_No: number) => useQuery(
  'allbiodata',
  () => {
    return getBiodata(VAT_No)
  },
  {
    keepPreviousData: true,
   
    
  }

)

export const useUpdatebiodata2 = (biodataToUpdate2: any) => useMutation({
  mutationFn: () => updateBiodata2(biodataToUpdate2)
});


export const useAllmemberdata = (start_limit: number, paginationsize: number, itemto_order: string, desorasc: string, search_item: string, user_role_id: number, user_designation: number) => useQuery(
  ['allmemberdata', start_limit, paginationsize],

  () => {

    return getAllmemberdata(start_limit, paginationsize, itemto_order, desorasc, search_item, user_role_id, user_designation)
  },
  {
    keepPreviousData: true,
    
  }
)