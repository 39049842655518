import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {debounce} from 'lodash'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {KTCard} from '../../_metronic/helpers'
import {KTCardBody} from '../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Maintable} from '../tables/Maintable'
import {useMemo} from 'react'

import LoadingBar from 'react-top-loading-bar'

import {useWindowWidth} from '@react-hook/window-size/throttled'

import CountUp from 'react-countup'
import '../css/popover.css'
import {Column} from 'react-table'
import {useAuth} from '../../app/modules/auth'
import {ItemsSummary} from '../topdashboard/ItemsSummary'
import {useMutation, useQueryClient} from 'react-query'
import io from 'socket.io-client'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {getAllmemberdata} from '../apis/Loadalltabledata'
import {useAllmemberdata} from '../contextProviders/Transactionsprovider'
import {Activatemembers} from './modal/Activatemembers'
import {Deactivatemembers} from './modal/Deactivatemembers'

const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const socket = io('machakosgolflocalsocket.smatonsolutions.com')

const Viewmembers = () => {
  const refloadingbar: any = useRef(null)
  const queryClient = useQueryClient()
  const myRefsearch: any = useRef()

  const {currentUser, logout} = useAuth()

  const [paginationsize, setPaginationsize] = useState<number>(10)

  const [startlimit, setStartlimit] = useState<number>(0)
  const [startlimitsumdash, setStartlimitsumdash] = useState<number>(0)
  const [database_limitsumdash, setDatabase_limitsumdash] = useState<number>(7)
  const [statusssumdash, setStatussumdash] = useState<string>('Credit_Customer_Id')
  const [statusnow, setStatusnow] = useState<boolean>(false)
  const [statusnow2, setStatusnow2] = useState<boolean>(false)
  const [itemto_order, setItemto_order] = useState<string>('Credit_Customer_Id')
  const [desorasc, setDesorasc] = useState<string>('ASC')
  const [serversort, setServersort] = useState<any>([])
  const [bioitemstoupdate, setBioitemstoupdate] = useState<any>({})
  const [isRefetchComplete, setIsRefetchComplete] = useState(false)
  const [deactivatemodal, setDeactivatemodal] = useState<boolean>(false)
  const [deactivationdata, setDeactivationdata] = useState<any>({})
  const [activatemodal, setActivatemodal] = useState<boolean>(false)
  const [activationdata, setActivationdata] = useState<any>({})
  const [search_item, setSearch_item] = useState<string>('%')
  const [pageCount, setPageCount] = useState<number>(0)
  const [balancesnew, setBalancesnew] = useState<any>([])
  const [creditdatanew, setCreditdatanew] = useState<any>([])
  const [debitdatanew, setDebitdatanew] = useState<any>([])

  const [hiddencolumnsmade, setHiddencolumnsmade] = useState<any>(['json_result'])
  const [hiddencolumnsmade2, setHiddencolumnsmade2] = useState<any>(['Project'])
  const [movetofirstpage, setMovetofirstpage] = useState<boolean>(false)

  const onlyWidth = useWindowWidth()
  const convertStringToJsonArray = (inputString: string) => {
    try {
      let jsonArray = JSON.parse(inputString)

      // Convert 'Date' fields from string to Date objects
      jsonArray = jsonArray.map((item: any) => {
        if (item.Date && item.Date.includes('function Date()')) {
          item.Date = new Date()
        }
        return item
      })

      return jsonArray
    } catch (error) {
      console.error('Error converting string to JSON:', error)
      return []
    }
  }
  const {
    isLoading: allmemberdataisloading,
    isSuccess: alltransactionissuccess,
    data: allmemberdatadata,
    isPreviousData: allmemberdataispreviousdata,
    status: allmemberdatastatus,
    refetch: allmemberdatarefetch,
    isFetching: allmemberdatafetching,
    isRefetching: allmemberdataisrefetching,
  } = useAllmemberdata(
    Number(startlimit),
    paginationsize,
    itemto_order,
    desorasc,
    search_item,
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id)
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refloadingbar.current.continuousStart()
        await allmemberdatarefetch()
      } catch (error) {
        console.log(error)
      } finally {
        refloadingbar.current.complete()
      }
    }
    fetchData()
  }, [itemto_order, desorasc, search_item])

  useEffect(() => {
    const handleValueChange = () => {
      if (myRefsearch.current.value === '' && myRefsearch.current === document.activeElement) {
        setSearch_item('%')
        setPaginationsize(10)
        setStartlimit(0)
        setItemto_order('Credit_Customer_Id')
        setDesorasc('ASC')
        setMovetofirstpage(true)
      }
    }

    const refSearch = myRefsearch.current
    if (refSearch) {
      refSearch.addEventListener('input', handleValueChange)
    }

    return () => {
      if (refSearch) {
        refSearch.removeEventListener('input', handleValueChange)
      }
    }
  }, [])

  useEffect(() => {
    if (serversort.length > 0) {
      setItemto_order(serversort.map((x: any) => x.id)[0])
      setDesorasc(serversort.map((x: any) => x.desc)[0] ? 'ASC' : 'ASC')
    } else {
      setItemto_order('Credit_Customer_Id')
      setDesorasc('ASC')
    }
  }, [serversort])

  const tabledata = useMemo(
    () => allmemberdatadata?.data.memberrecords,
    [allmemberdatadata?.data.memberrecords, alltransactionissuccess]
  )

  const tabledatalength = useMemo(
    () => allmemberdatadata?.data.memberrecordCount,
    [allmemberdatadata?.data.memberrecordCount, alltransactionissuccess]
  )

  const tablename = 'Transaction Summary'

  const filteredsubdata = (rowdata: any): number => {
    return JSON.parse(rowdata.values.json_result).length
  }

  const filteredtablesubData = (rowdata: any): any => {
    return JSON.parse(rowdata.values.json_result)
  }

  const handlesearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const inputValue = event.target.value
      if (inputValue) {
        setSearch_item('%' + inputValue + '%')
      } else {
        setSearch_item('%')
      }
      setPaginationsize(10)
      setStartlimit(0)
      setItemto_order('Credit_Customer_Id')
      setDesorasc('ASC')
      setMovetofirstpage(true)
    }
  }

  const handleinputs = (event: any) => {
    if (myRefsearch.current.value === '' || event.target.value === '') {
      setSearch_item('%')
      setPaginationsize(10)
      setStartlimit(0)
      setItemto_order('Credit_Customer_Id')
      setDesorasc('ASC')
      setMovetofirstpage(true)
    }
  }

  const handlesearchKeyDown2 = (event: any) => {
    event.preventDefault()

    const inputValue = myRefsearch.current.value || event.target.value

    if (inputValue) {
      setSearch_item('%' + inputValue + '%')
    } else {
      setSearch_item('%')
    }

    setPaginationsize(10)
    setStartlimit(0)
    setItemto_order('Credit_Customer_Id')
    setDesorasc('ASC')
    setMovetofirstpage(true)
  }

  useEffect(() => {
    // Define the function that will handle the 'update' event
    const handleSocketUpdate = (notification: any) => {
      ;(async () => {
        try {
          await refloadingbar.current.continuousStart()

          await allmemberdatarefetch()
          console.log(notification)
        } catch (error) {
          console.log(error)
        } finally {
          await refloadingbar.current.complete()
        }
      })()
    }

    socket.on('update', handleSocketUpdate)

    return () => {
      socket.off('update', handleSocketUpdate)
    }
  }, [])

  useEffect(() => {
    // Function to check if there is a next page
    const hasNextPage = (currentPage: number, pageSize: number, totalItems: number) => {
      const totalPages = Math.ceil(totalItems / pageSize)
      return currentPage < totalPages
    }

    const currentPage = Math.floor(startlimit / paginationsize) + 1

    // Check if next page exists
    if (hasNextPage(currentPage, paginationsize, tabledatalength)) {
      const nextPage = currentPage + 1
      const nextStartLimit = (nextPage - 1) * paginationsize

      // Prefetch the next page data
      queryClient.prefetchQuery(['allmemberdata', nextStartLimit, paginationsize], () =>
        getAllmemberdata(
          nextStartLimit,
          paginationsize,
          itemto_order,
          desorasc,
          search_item,
          Number(currentUser?.user_role_id),
          Number(currentUser?.user_designation_id)
        )
      )
    }
  }, [
    startlimit,
    paginationsize,
    tabledatalength,
    queryClient,
    itemto_order,
    desorasc,
    search_item,
    currentUser,
  ])

  const columndata1: ReadonlyArray<
    Column<{
      Credit_Customer_Id: number
      Business_Name: string
      Physical_address: string
      Postal_Address: string
      Telephone: string
      Email: string
      Opening_Balance: number
      Open_Balance: number
      PIN_No: string
      VAT_No: number
      Status: string
      Min_Spend_Member_Id: string
      Total_Debit: number
      Subscriptions_Total: number
      Link_Status: string
      Linked_VAT_No: number
      Access_id: string
    }>
  > = [
    {
      Header: () => <div className='w-100 text-muted'>Name</div>,
      accessor: 'Business_Name',

      Cell: ({cell: {value}, row}: any) => (
        <>
          <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6'>{value}</span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
            {row.original.Linked_VAT_No}
          </span>
        </>
      ),
    },
    {
      Header: () => <div className='w-100'>Contact Info.</div>,
      accessor: 'Telephone',

      Cell: ({cell: {value}, row}: any) => (
        <>
          <span className='text-primary text-nowrap  fw-bolder d-block mb-1 fs-6'>{value}</span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
            {row.original.Email}
          </span>
        </>
      ),
    },
    {
      Header: () => <div className='w-100'>Status</div>,
      accessor: 'Status',

      Cell: ({cell: {value}, row}: any) => (
        <>
          <span
            className={`${
              value === 'ACTIVE' || value === 'LIFE MEMBER'
                ? 'badge badge-light-success text-success fw-bold'
                : 'badge badge-light-danger text-danger fw-bold'
            } text-nowrap  fw-bolder d-block mb-1 fs-6`}
          >
            {value}
          </span>
          <span className='badge badge-light text-mute text-nowrap  fw-bolder d-block mb-1 fs-6'>
            {addCommas(row.original.Opening_Balance.toFixed(2))} {CURRENCY_APP}
          </span>
        </>
      ),
    },

    {
      Header: () => <div className='w-100 text-end'>Spend</div>,
      accessor: 'Total_Debit',

      Cell: ({cell: {value}, row}: any) => (
        <>
          <span
            className={`${
              value >= 1500 ? 'text-primary' : 'text-danger'
            } text-nowrap fw-bolder d-block mb-1 fs-6 text-end`}
          >
            {addCommas(value.toFixed(2))} {CURRENCY_APP}
          </span>
          <span className='text-info text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
            {addCommas(row.original.Subscriptions_Total.toFixed(2))} {CURRENCY_APP}
          </span>
        </>
      ),
    },

    {
      Header: () => null,
      id: 'expander2',
      Cell: ({row}: any) => {
        return (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <a
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setActivationdata(row.original)
                setActivatemodal(true)
              }}
              title='Link Account'
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
              <span className='svg-icon svg-icon-3'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32'
                  height='32'
                  fill='#7239ea'
                  version='1.1'
                  viewBox='0 0 31.891 31.891'
                  xmlSpace='preserve'
                >
                  <path d='M30.543 5.74l-4.078-4.035c-1.805-1.777-4.736-1.789-6.545-.02l-4.525 4.414a4.486 4.486 0 00-.02 6.424l2.586-2.484c-.262-.791.061-1.697.701-2.324l2.879-2.807a2.346 2.346 0 013.275.01l2.449 2.42c.9.891.896 2.326-.01 3.213l-2.879 2.809c-.609.594-1.609.92-2.385.711l-2.533 2.486a4.688 4.688 0 006.545.02l4.52-4.41a4.482 4.482 0 00.02-6.427zM13.975 21.894c.215.773-.129 1.773-.752 2.381l-2.689 2.627c-.922.9-2.414.895-3.332-.012l-2.498-2.461a2.29 2.29 0 01.012-3.275l2.691-2.627c.656-.637 1.598-.961 2.42-.689l2.594-2.57c-1.836-1.811-4.824-1.82-6.668-.02l-4.363 4.26a4.574 4.574 0 00-.02 6.549l4.154 4.107c1.834 1.809 4.82 1.818 6.668.018l4.363-4.26a4.576 4.576 0 00.02-6.547l-2.6 2.519z'></path>
                  <path d='M11.139 20.722a1.581 1.581 0 002.234.008l7.455-7.416a1.576 1.576 0 00.008-2.234 1.58 1.58 0 00-2.23-.006l-7.457 7.414a1.58 1.58 0 00-.01 2.234z'></path>
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </a>
            {row.original.Status === 'INACTIVE' ? (
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Activate Account'
                onClick={() => {
                  setActivationdata(row.original)
                  setActivatemodal(true)
                }}
              >
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle cx='12' cy='12' r='10' stroke='green' strokeWidth='3' />
                    <path d='M7 12l3 3 7-7' stroke='green' strokeWidth='3' fill='none' />
                  </svg>
                </span>
              </a>
            ) : (
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Disable Account'
                onClick={() => {
                  setDeactivationdata(row.original)
                  setDeactivatemodal(true)
                }}
              >
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle cx='12' cy='12' r='10' stroke='red' strokeWidth='3' />
                    <line x1='4.93' y1='4.93' x2='19.07' y2='19.07' stroke='red' strokeWidth='3' />
                  </svg>
                </span>
              </a>
            )}
          </div>
        )
      },
    },
  ]

  const columndata3: ReadonlyArray<
    Column<{
      Credit_Customer_Id: number
      Business_Name: string
      Physical_address: string
      Postal_Address: string
      Telephone: string
      Email: string
      Opening_Balance: number
      Open_Balance: number
      PIN_No: string
      VAT_No: number
      Status: string
      Min_Spend_Member_Id: string
      Total_Debit: number
      Subscriptions_Total: number
      Link_Status: string
      Linked_VAT_No: number
      Access_id: string
    }>
  > = [
    {
      Header: () => <div className='w-100 text-muted'>Name</div>,
      accessor: 'Business_Name',

      Cell: ({cell: {value}, row}: any) => (
        <>
          <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6'>{value}</span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
            {row.original.Linked_VAT_No} <span className={`${row.original.Status === 'ACTIVE' || row.original.Status === 'LIFE MEMBER'? 'badge badge-light-success text-success fw-bold' : 'badge badge-light-danger text-danger fw-bold'} text-nowrap  fw-bolder fs-7`}> {row.original.Status}</span>
          </span>
          <span className='text-muted text-nowrap fw-bold mb-1 fs-7'>
          SPEND: <span className={`${value >= 1500 ? 'text-primary' : 'text-danger'} text-nowrap fw-bolder fs-7 text-end`}>{addCommas(row.original.Total_Debit.toFixed(2))} {CURRENCY_APP}</span> SUB: <span className='text-info text-nowrap  fw-bolder  fs-7 text-end'>{addCommas(row.original.Subscriptions_Total.toFixed(2))} {CURRENCY_APP}</span>
          </span>
          <div className='d-flex justify-content-end flex-shrink-0 d-block mt-1'>
            <a
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setActivationdata(row.original)
                setActivatemodal(true)
              }}
              title='Link Account'
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
              <span className='svg-icon svg-icon-3'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32'
                  height='32'
                  fill='#7239ea'
                  version='1.1'
                  viewBox='0 0 31.891 31.891'
                  xmlSpace='preserve'
                >
                  <path d='M30.543 5.74l-4.078-4.035c-1.805-1.777-4.736-1.789-6.545-.02l-4.525 4.414a4.486 4.486 0 00-.02 6.424l2.586-2.484c-.262-.791.061-1.697.701-2.324l2.879-2.807a2.346 2.346 0 013.275.01l2.449 2.42c.9.891.896 2.326-.01 3.213l-2.879 2.809c-.609.594-1.609.92-2.385.711l-2.533 2.486a4.688 4.688 0 006.545.02l4.52-4.41a4.482 4.482 0 00.02-6.427zM13.975 21.894c.215.773-.129 1.773-.752 2.381l-2.689 2.627c-.922.9-2.414.895-3.332-.012l-2.498-2.461a2.29 2.29 0 01.012-3.275l2.691-2.627c.656-.637 1.598-.961 2.42-.689l2.594-2.57c-1.836-1.811-4.824-1.82-6.668-.02l-4.363 4.26a4.574 4.574 0 00-.02 6.549l4.154 4.107c1.834 1.809 4.82 1.818 6.668.018l4.363-4.26a4.576 4.576 0 00.02-6.547l-2.6 2.519z'></path>
                  <path d='M11.139 20.722a1.581 1.581 0 002.234.008l7.455-7.416a1.576 1.576 0 00.008-2.234 1.58 1.58 0 00-2.23-.006l-7.457 7.414a1.58 1.58 0 00-.01 2.234z'></path>
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </a>
            {row.original.Status === 'INACTIVE' ? (
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Activate Account'
                onClick={() => {
                  setActivationdata(row.original)
                  setActivatemodal(true)
                }}
              >
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle cx='12' cy='12' r='10' stroke='green' strokeWidth='3' />
                    <path d='M7 12l3 3 7-7' stroke='green' strokeWidth='3' fill='none' />
                  </svg>
                </span>
              </a>
            ) : (
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Disable Account'
                onClick={() => {
                  setDeactivationdata(row.original)
                  setDeactivatemodal(true)
                }}
              >
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle cx='12' cy='12' r='10' stroke='red' strokeWidth='3' />
                    <line x1='4.93' y1='4.93' x2='19.07' y2='19.07' stroke='red' strokeWidth='3' />
                  </svg>
                </span>
              </a>
            )}
          </div>
        </>
      ),
    },
  
   
  
  ]

  const columndata5: ReadonlyArray<
    Column<{
      Credit_Customer_Id: number
      Business_Name: string
      Physical_address: string
      Postal_Address: string
      Telephone: string
      Email: string
      Opening_Balance: number
      Open_Balance: number
      PIN_No: string
      VAT_No: number
      Status: string
      Min_Spend_Member_Id: string
      Total_Debit: number
      Subscriptions_Total: number
      Link_Status: string
      Linked_VAT_No: number
      Access_id: string
    }>
  > = [
    {
      Header: () => <div className='w-100 text-muted'>Name</div>,
      accessor: 'Business_Name',

      Cell: ({cell: {value}, row}: any) => (
        <>
          <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6'>{value}</span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
            {row.original.Linked_VAT_No}
          </span>
        </>
      ),
    },

    {
      Header: () => <div className='w-100 text-end'>Spend</div>,
      accessor: 'Total_Debit',

      Cell: ({cell: {value}, row}: any) => (
        <>
          <span
            className={`${
              value >= 1500 ? 'text-primary' : 'text-danger'
            } text-nowrap fw-bolder d-block mb-1 fs-6 text-end`}
          >
            {addCommas(value.toFixed(2))} {CURRENCY_APP}
          </span>
          <span className='text-mute text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
            {addCommas(row.original.Opening_Balance.toFixed(2))} {CURRENCY_APP}
          </span>
        </>
      ),
    },
    {
      Header: () => null,
      id: 'expander2',
      Cell: ({row}: any) => {
        return (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <a
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setActivationdata(row.original)
                setActivatemodal(true)
              }}
              title='Link Account'
            >
              {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
              <span className='svg-icon svg-icon-3'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32'
                  height='32'
                  fill='#7239ea'
                  version='1.1'
                  viewBox='0 0 31.891 31.891'
                  xmlSpace='preserve'
                >
                  <path d='M30.543 5.74l-4.078-4.035c-1.805-1.777-4.736-1.789-6.545-.02l-4.525 4.414a4.486 4.486 0 00-.02 6.424l2.586-2.484c-.262-.791.061-1.697.701-2.324l2.879-2.807a2.346 2.346 0 013.275.01l2.449 2.42c.9.891.896 2.326-.01 3.213l-2.879 2.809c-.609.594-1.609.92-2.385.711l-2.533 2.486a4.688 4.688 0 006.545.02l4.52-4.41a4.482 4.482 0 00.02-6.427zM13.975 21.894c.215.773-.129 1.773-.752 2.381l-2.689 2.627c-.922.9-2.414.895-3.332-.012l-2.498-2.461a2.29 2.29 0 01.012-3.275l2.691-2.627c.656-.637 1.598-.961 2.42-.689l2.594-2.57c-1.836-1.811-4.824-1.82-6.668-.02l-4.363 4.26a4.574 4.574 0 00-.02 6.549l4.154 4.107c1.834 1.809 4.82 1.818 6.668.018l4.363-4.26a4.576 4.576 0 00.02-6.547l-2.6 2.519z'></path>
                  <path d='M11.139 20.722a1.581 1.581 0 002.234.008l7.455-7.416a1.576 1.576 0 00.008-2.234 1.58 1.58 0 00-2.23-.006l-7.457 7.414a1.58 1.58 0 00-.01 2.234z'></path>
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </a>
            {row.original.Status === 'INACTIVE' ? (
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Activate Account'
                onClick={() => {
                  setActivationdata(row.original)
                  setActivatemodal(true)
                }}
              >
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle cx='12' cy='12' r='10' stroke='green' strokeWidth='3' />
                    <path d='M7 12l3 3 7-7' stroke='green' strokeWidth='3' fill='none' />
                  </svg>
                </span>
              </a>
            ) : (
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Disable Account'
                onClick={() => {
                  setDeactivationdata(row.original)
                  setDeactivatemodal(true)
                }}
              >
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle cx='12' cy='12' r='10' stroke='red' strokeWidth='3' />
                    <line x1='4.93' y1='4.93' x2='19.07' y2='19.07' stroke='red' strokeWidth='3' />
                  </svg>
                </span>
              </a>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Transactions</PageTitle>
      {/* begin::Row */}
      {/* begin::Row */}

      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}

        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='120px'
            cardName='Balance'
            chartName='Balance'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...balancesnew?.map((x: any) => x.Closing_Balance)))}
            chartCategories={balancesnew
              ?.sort((a: any, b: any) => (a.Credit_Customer_Id > b.Credit_Customer_Id ? 1 : -1))
              .map((x: any) => dayjs(x.Credit_Customer_Id).format('LL'))}
            chartData={balancesnew
              ?.sort((a: any, b: any) => (a.Credit_Customer_Id > b.Credit_Customer_Id ? 1 : -1))
              .map((x: any) => x.Closing_Balance)}
            values={
              <>
                <div className='fs-2 fw-bolder d-block '>
                  <CountUp
                    className='text-primary'
                    end={
                      balancesnew
                        ?.sort((a: any, b: any) =>
                          a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                        )
                        .map((x: any) => x.Closing_Balance)[0]
                    }
                    duration={2}
                    decimals={2}
                    separator=','
                  />{' '}
                  <sup className='fs-6 text-primary'>{CURRENCY_APP}</sup>
                </div>
              </>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {balancesnew
                        ?.sort((a: any, b: any) =>
                          a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                        )
                        .map((x: any) => x.Credit_Customer_Id)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                balancesnew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Credit_Customer_Id)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                balancesnew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Closing_Balance)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {balancesnew
                        ?.sort((a: any, b: any) =>
                          a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                        )
                        .map((x: any) => x.Credit_Customer_Id)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                balancesnew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Credit_Customer_Id)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                balancesnew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Closing_Balance)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='success'
            chartHeight='120px'
            cardName='Credit'
            chartName='Credit'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...debitdatanew?.map((x: any) => x.TotalDebit)))}
            chartCategories={debitdatanew
              ?.sort((a: any, b: any) => (a.Credit_Customer_Id > b.Credit_Customer_Id ? 1 : -1))
              .map((x: any) => dayjs(x.Credit_Customer_Id).format('LL'))}
            chartData={debitdatanew
              ?.sort((a: any, b: any) => (a.Credit_Customer_Id > b.Credit_Customer_Id ? 1 : -1))
              .map((x: any) => x.TotalDebit)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-success'
                  end={
                    debitdatanew
                      ?.sort((a: any, b: any) =>
                        a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                      )
                      .map((x: any) => x.TotalDebit)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-success fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {debitdatanew
                        ?.sort((a: any, b: any) =>
                          a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                        )
                        .map((x: any) => x.Credit_Customer_Id)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                                debitdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Credit_Customer_Id)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                debitdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalDebit)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {debitdatanew
                        ?.sort((a: any, b: any) =>
                          a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                        )
                        .map((x: any) => x.Credit_Customer_Id)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                                debitdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Credit_Customer_Id)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                debitdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalDebit)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='120px'
            cardName='Debit'
            chartName='Debit'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...creditdatanew?.map((x: any) => x.TotalCredit)))}
            chartCategories={creditdatanew
              ?.sort((a: any, b: any) => (a.Credit_Customer_Id > b.Credit_Customer_Id ? 1 : -1))
              .map((x: any) => dayjs(x.Credit_Customer_Id).format('LL'))}
            chartData={creditdatanew
              ?.sort((a: any, b: any) => (a.Credit_Customer_Id > b.Credit_Customer_Id ? 1 : -1))
              .map((x: any) => x.TotalCredit)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-danger'
                  end={
                    creditdatanew
                      ?.sort((a: any, b: any) =>
                        a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                      )
                      .map((x: any) => x.TotalCredit)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-danger fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {creditdatanew
                        ?.sort((a: any, b: any) =>
                          a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                        )
                        .map((x: any) => x.Credit_Customer_Id)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-danger'>
                              {dayjs(
                                creditdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Credit_Customer_Id)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                creditdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalCredit)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {creditdatanew
                        ?.sort((a: any, b: any) =>
                          a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                        )
                        .map((x: any) => x.Credit_Customer_Id)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-danger'>
                              {dayjs(
                                creditdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.Credit_Customer_Id)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                creditdatanew
                                  ?.sort((a: any, b: any) =>
                                    a.Credit_Customer_Id > b.Credit_Customer_Id ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalCredit)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-12'>
          <KTCard>
            <KTCardBody className='py-4'>
              <div className='row mt-4'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                  <div className='mb-2'>
                    <h3 className='fw-bolder my-2'>Member Data</h3>
                  </div>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                      <a href='#' onClick={handlesearchKeyDown2}>
                        <KTSVG
                          path='/media/icons/duotune/general/gen021.svg'
                          className='svg-icon-1 position-absolute me-4 top-0 end-0 mt-3 text-primary'
                        />
                      </a>
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Transactions'
                        ref={myRefsearch}
                        // value={searchTitle}
                        onChange={handleinputs}
                        onKeyDown={handlesearchKeyDown}
                      />
                    </div>
                    {/* end::Search */}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'></div>
                </div>
              </div>

              <Maintable
                tabledata={alltransactionissuccess ? tabledata : []}
                startlimit={startlimit}
                hiddencolumnsmade={hiddencolumnsmade}
                hiddencolumnsmade2={hiddencolumnsmade2}
                setstartlimit={setStartlimit}
                paginationsize={paginationsize}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPaginationsize={setPaginationsize}
                movetofirstpage={movetofirstpage}
                setMovetofirstpage={setMovetofirstpage}
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : columndata1
                }
                tabledata2={tabledata}
                table1loaded={allmemberdatafetching}
                table2loaded={allmemberdatafetching}
                tablename={tablename}
                tablelength={allmemberdatastatus === 'success' ? tabledatalength : 0}
                filteredsubdata={filteredsubdata}
                filteredtablesubData={filteredtablesubData}
                setServersort={setServersort}
              />
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      <Deactivatemembers
        show={deactivatemodal}
        handleClose={function (): void {
          setDeactivatemodal(false)
        }}
        deactivationdata={deactivationdata}
        allmemberdatarefetch={allmemberdatarefetch}
      />

      <Activatemembers
        show={activatemodal}
        handleClose={function (): void {
          setActivatemodal(false)
        }}
        activationdata={activationdata}
        allmemberdatarefetch={allmemberdatarefetch}
      />
    </>
  )
}

export default Viewmembers
