import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import React, {FC, useEffect, useState, useCallback, useRef} from 'react'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'
import {KTCard, toAbsoluteUrl} from '../../_metronic/helpers'
import {KTCardBody} from '../../_metronic/helpers'
import {KTSVG} from '../../_metronic/helpers'
import {useLocation, useNavigate} from 'react-router-dom'
import axios from 'axios'
import {Maintable} from '../tables/Maintable'
import {useMemo} from 'react'
import { ItemsSummary } from '../topdashboard/ItemsSummary'
import CountUp from 'react-countup'
import LoadingBar from 'react-top-loading-bar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Column} from 'react-table'
import {useAuth} from '../../app/modules/auth'
import {useWindowWidth} from '@react-hook/window-size/throttled'
import ReactPaginate from 'react-paginate'



const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'No Rights',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]




const Home = () => {
 
  const navigate = useNavigate(); 
  const gotoHome = () =>{ 
    let path = `/home`; 
    navigate(path);
  }
 
    

  return (
    <>
     
      <PageTitle breadcrumbs={usersBreadcrumbs}>User Rights</PageTitle>
     
      <KTCard>
        <KTCardBody className='py-4'>
        <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('/media/auth/access_forbbiden.svg')}
          alt=''
          className='mw-350px mb-10 h-lg-100px'
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
          RESTRICTED AREA
        </h1>
        {/* end::Message */}
        {/* begin::Link */}
              <a
              className='btn btn-sm btn-primary cursor-pointer'
            
              onClick={() => gotoHome()}
            >
              <KTSVG path='/media/icons/duotune/art/art002.svg' className='svg-icon-2' />
              Go To Homepage
            </a>
        {/* end::Link */}
      </div>
    </div>
        </KTCardBody>
      </KTCard>
     
    </>
  )
}

export default Home
