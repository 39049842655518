import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {debounce} from 'lodash'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {KTCard} from '../../_metronic/helpers'
import {KTCardBody} from '../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Maintable} from '../tables/Maintable'
import {useMemo} from 'react'

import LoadingBar from 'react-top-loading-bar'

import {useWindowWidth} from '@react-hook/window-size/throttled'
import {

  useAlltransactionsquery,
  useAlltransactionsquery2,
  useAlltransactionsquery3,
  useAlltransactionsquery4,
} from '../contextProviders/Transactionsprovider'
import CountUp from 'react-countup'
import '../css/popover.css'
import {Column} from 'react-table'
import {useAuth} from '../../app/modules/auth'
import {ItemsSummary} from '../topdashboard/ItemsSummary'
import {useMutation, useQueryClient} from 'react-query'
import io from 'socket.io-client'



import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { getAlltransactions } from '../apis/Loadalltabledata'
import { useBiodataContext } from '../contextProviders/BiodataContext'





const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const socket = io('machakosgolflocalsocket.smatonsolutions.com')



const Home = () => {

  const refloadingbar: any = useRef(null)
  const queryClient = useQueryClient()
  const myRefsearch: any = useRef()


  const {currentUser, logout} = useAuth()
 
  const [paginationsize, setPaginationsize] = useState<number>(10)

  const [startlimit, setStartlimit] = useState<number>(0)
  const [startlimitsumdash, setStartlimitsumdash] = useState<number>(0)
  const [database_limitsumdash, setDatabase_limitsumdash] = useState<number>(7)
  const [statusssumdash, setStatussumdash] = useState<string>('GroupedDate')
  const [statusnow, setStatusnow] = useState<boolean>(false)
  const [statusnow2, setStatusnow2] = useState<boolean>(false)
  const [itemto_order, setItemto_order] = useState<string>('GroupedDate')
  const [desorasc, setDesorasc] = useState<string>('DESC')
  const [serversort, setServersort] = useState<any>([])
  
  const [search_item, setSearch_item] = useState<string>('%')
  const [pageCount, setPageCount] = useState<number>(0)
  const [balancesnew, setBalancesnew] = useState<any>([])
  const [creditdatanew, setCreditdatanew] = useState<any>([])
  const [debitdatanew, setDebitdatanew] = useState<any>([])
 
  const [hiddencolumnsmade, setHiddencolumnsmade] = useState<any>(['json_result'])
  const [hiddencolumnsmade2, setHiddencolumnsmade2] = useState<any>(['Project'])
  const [movetofirstpage, setMovetofirstpage] = useState<boolean>(false)
  const {modalUpdatepassword, setModalUpdatepassword, modalpasswordchange,  setModalPasswordchange} = useBiodataContext();


  const onlyWidth = useWindowWidth()
  const convertStringToJsonArray = (inputString: string) => {
    try {
      let jsonArray = JSON.parse(inputString);

      // Convert 'Date' fields from string to Date objects
      jsonArray = jsonArray.map((item: any) => {
        if (item.Date && item.Date.includes('function Date()')) {
          item.Date = new Date();
        }
        return item;
      });

      return jsonArray;
    } catch (error) {
      console.error('Error converting string to JSON:', error);
      return [];
    }
  };
  const {
    isLoading: alltransactionsisloading,
    isSuccess: alltransactionissuccess,
    data: alltransactionsdata,
    isPreviousData: alltransactionsispreviousdata,
    status: alltransactionsstatus,
    isRefetching: alltransactionsrefetching,
    refetch: alltransactionsrefetch
  } = useAlltransactionsquery(
    Number(currentUser?.member_id),
    startlimit,
    paginationsize,
    itemto_order,
    desorasc,
    search_item
  )

//balances
  const {
    isLoading: balancesisloading,
    isSuccess: balancesissuccess,
    data: balancesdata,
    isPreviousData: balancesispreviousdata,
    status: balancesstatus,
    refetch: balancesrefetch,
    isRefetching: balanceisRefetching
  } = useAlltransactionsquery2(
    Number(currentUser?.member_id),
    startlimitsumdash,
    database_limitsumdash,
    statusssumdash,
    "DESC",
    "%"
  )

  //credit
  const {
    isLoading: creditisloading,
    isSuccess: creditissuccess,
    data: creditsdata,
    isPreviousData: creditispreviousdata,
    status: creditstatus,
    refetch: creditrefetch,
    isRefetching: creditisRefetching
  } = useAlltransactionsquery3(
    Number(currentUser?.member_id),
    startlimitsumdash,
    database_limitsumdash,
    statusssumdash,
    "DESC",
    "%"
  )

//debit

  const {
    isLoading: debitisloading,
    isSuccess: debitissuccess,
    data: debitdata,
    isPreviousData: debitispreviousdata,
    status: debitstatus,
    refetch: debitrefetch,
    isRefetching:debitisRefetching
  } = useAlltransactionsquery4(
    Number(currentUser?.member_id),
    startlimitsumdash,
    database_limitsumdash,
    statusssumdash,
    "DESC",
    "%"
  )



  useEffect(() => {
    const fetchData = async () => {
      try {
        await refloadingbar.current.continuousStart()
        await alltransactionsrefetch()
      } catch (error) {
        console.log(error)
      } finally {
        refloadingbar.current.complete()
      }
    }
    fetchData()
  }, [itemto_order, desorasc, search_item])

  useEffect(()=>{
   

    if(currentUser?.member_id === Number(currentUser?.password)){
 
   setModalPasswordchange(true)
    }
    else{
  
     setModalPasswordchange(false)
    }
 
   },[])
 

  useEffect(() => {
    const handleValueChange = () => {
      if (myRefsearch.current.value === '' && myRefsearch.current === document.activeElement) {
        setSearch_item('%')
        setPaginationsize(10)
        setStartlimit(0)
        setItemto_order('GroupedDate')
        setDesorasc('DESC')
        setMovetofirstpage(true)
      }
    }

    const refSearch = myRefsearch.current
    if (refSearch) {
      refSearch.addEventListener('input', handleValueChange)
    }

    return () => {
      if (refSearch) {
        refSearch.removeEventListener('input', handleValueChange)
      }
    }
  }, [])

  useEffect(() => {
    if (serversort.length > 0) {
      setItemto_order(serversort.map((x: any) => x.id)[0])
      setDesorasc(serversort.map((x: any) => x.desc)[0] ? 'DESC' : 'ASC')
    } else {
      setItemto_order('GroupedDate')
      setDesorasc('DESC')
    }
  }, [serversort])


  const tabledata = useMemo(
    () => alltransactionsdata?.data.records,
    [alltransactionsdata?.data.records, alltransactionissuccess]
  )

  const tabledatalength = useMemo(
    () => alltransactionsdata?.data.recordecount,
    [alltransactionsdata?.data.recordecount, alltransactionissuccess]
  )

  const tablename = 'Transaction Summary'

  const filteredsubdata = (rowdata: any): number => {
    return JSON.parse(rowdata.values.json_result).length
  }

  const filteredtablesubData = (rowdata: any): any => {

    return JSON.parse(rowdata.values.json_result)
  }



 
  useEffect(() => {
    if (!balanceisRefetching && balancesissuccess) {
     
        setBalancesnew(balancesdata?.data.records)
      
        
      }
    

    if (!creditisRefetching && creditissuccess) {
      
        setCreditdatanew(creditsdata?.data.records)
      
      
    }

    if (!debitisRefetching && debitissuccess) {
     
        setDebitdatanew(debitdata?.data.records)
      
    }
  }, [
    creditisRefetching,
    debitisRefetching,
    balanceisRefetching,
    debitissuccess,
    creditissuccess,
    balancesissuccess
  ])

 


  const handlesearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const inputValue = event.target.value
      if (inputValue) {
        setSearch_item('%' + inputValue + '%')
      } else {
        setSearch_item('%')
      }
      setPaginationsize(10)
      setStartlimit(0)
      setItemto_order('GroupedDate')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handleinputs = (event: any) => {
    if (myRefsearch.current.value === '' || event.target.value === '') {
      setSearch_item('%')
      setPaginationsize(10)
      setStartlimit(0)
      setItemto_order('GroupedDate')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handlesearchKeyDown2 = (event: any) => {
    event.preventDefault()

    const inputValue = myRefsearch.current.value || event.target.value

    if (inputValue) {
      setSearch_item('%' + inputValue + '%')
    } else {
      setSearch_item('%')
    }

    setPaginationsize(10)
    setStartlimit(0)
    setItemto_order('GroupedDate')
    setDesorasc('DESC')
    setMovetofirstpage(true)
  }



  useEffect(() => {
    // Define the function that will handle the 'update' event
    const handleSocketUpdate = (notification: any) => {
      (async () => {
        try {
          await refloadingbar.current.continuousStart();
          await debitrefetch();
          await creditrefetch();
          await balancesrefetch();
          await alltransactionsrefetch();
          console.log(notification);
        
        } catch (error) {
          console.log(error);
        } finally {
          await refloadingbar.current.complete();
        }
      })();
    };

    
    socket.on('update', handleSocketUpdate);

    return () => {
      socket.off('update', handleSocketUpdate);
    };
  }, []);

  useEffect(() => {
    // Function to check if there is a next page
    const hasNextPage = (currentPage: number, pageSize : number, totalItems : number) => {
      const totalPages = Math.ceil(totalItems / pageSize);
      return currentPage < totalPages;
    };

    // Calculate the current page number
    const currentPage = Math.floor(startlimit / paginationsize) + 1;

    // Check if next page exists
    if (hasNextPage(currentPage, paginationsize, tabledatalength)) {
      const nextPage = currentPage + 1;
      const nextStartLimit = (nextPage - 1) * paginationsize;

      // Prefetch the next page data
      queryClient.prefetchQuery(
        ['alltransactionsquery', nextStartLimit, paginationsize],
        () =>
          getAlltransactions(
            Number(currentUser?.member_id),
            nextStartLimit,
            paginationsize,
            itemto_order,
            desorasc,
            search_item
          )
      );
    }
  }, [
    startlimit,
    paginationsize,
    tabledatalength,
    queryClient,
    itemto_order,
    desorasc,
    search_item,
    currentUser
  ]);
  

  const columndata1: ReadonlyArray<
    Column<{
      GroupedDate: string
      Total_transactions: number
      TotalDebit: number
      TotalCredit: number
      Opening_Balance: number
      Closing_Balance: number
      json_result: string  
    }>
  > = [
    {
      Header: () => null,
      id: 'expander',

      Cell: ({row}: any) => {
        return (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <>
                <span className='svg-icon svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <path d='M0 0L24 0 24 24 0 24z'></path>
                      <path
                        fill='#009ef7'
                        fillRule='nonzero'
                        d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                      ></path>
                    </g>
                  </svg>
                </span>
              </>
            ) : (
              <>
                <span className='svg-icon svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <path d='M0 0L24 0 24 24 0 24z'></path>
                      <path
                        fill='#009ef7'
                        fillRule='nonzero'
                        d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                      ></path>
                    </g>
                  </svg>
                </span>
              </>
            )}
          </span>
        )
      },
    },
    {
      Header: 'json_result',
      accessor: 'json_result'
    },
    {
      Header: () => (<div className="w-100 text-start">Date</div>),
      accessor: 'GroupedDate',
      Cell: ({cell: {value}}) => (
        <>
          <span className='text-dark text-nowrap fw-bolder d-block mb-1 fs-6'>
            {dayjs(value).format('LL')}
          </span>
          <span className='text-muted  text-nowrap fw-bold text-muted d-block fs-7'>
            {dayjs(value).format('dddd')}
          </span>
        </>
      ),
    },
    {
      Header:() => (<div className="w-100 text-end">T.Count</div>),
      accessor: 'Total_transactions',

      Cell: ({cell: {value}, row}) => (
        <>
          <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
         {value}
          </span>

        </>
      ),
    },
    {
      Header: () => (<div className="w-100 text-end">T.Credit</div>),
      accessor: 'TotalDebit',

      Cell: ({cell: {value}, row}) =>
      <>
      <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
      {addCommas(value.toFixed(2))} {CURRENCY_APP}
    </span>
    </>
    },
    {
      Header: () => (<div className="w-100 text-end">T.Debit</div>),
      accessor: 'TotalCredit',

      Cell: ({cell: {value}, row}) =>
      <>
      <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
      {addCommas(value.toFixed(2))} {CURRENCY_APP}
    </span>
    </>
    },
    {
      Header: () => (<div className="w-100 text-end">O.Balance</div>),
      accessor: 'Opening_Balance',

      Cell: ({cell: {value}, row}) =>
      <>
      <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
      {addCommas(value.toFixed(2))} {CURRENCY_APP}
    </span>
    </>
    },
    {
      Header: () => (<div className="w-100 text-end">C.Balance</div>),
      accessor: 'Closing_Balance',

      Cell: ({cell: {value}, row}) =>
      <>
      <span className='text-primary text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
      {addCommas(value.toFixed(2))} {CURRENCY_APP}
    </span>
    </>
    }

  ]
  const columndata2: ReadonlyArray<
    Column<{
      Narration: string,
      Debit: number,
      Credit: number,
      Balance: number,
      Project: number,
      Datereport: string
    }>
    > = [
      
      {
        Header: 'Project',
        accessor: 'Project'
      },
      {
        Header:'Time',
        accessor: 'Datereport',
  
        Cell: ({cell: {value}, row}) => (
          <>
            <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6'>
            {dayjs(value).format('LT')}
            </span>
  
          </>
        ),
      },
      {
        Header: 'Item',
        accessor: 'Narration',
  
        Cell: ({cell: {value}}) => (
          <>
           {value === "A DEBIT WAS MADE TO THE ACCOUNT" ?
            <span className='text-success text-nowrap fw-bold d-block fs-7'>
              {"A CREDIT WAS MADE TO THE ACCOUNT"}
            </span>
                  :
                  <span className='text-nowrap fw-bold  d-block fs-7 '>
                  {value}
                </span>   }
          </>
        ),
      },
      {
        Header: () => (<div className="w-100 text-end">Credit</div>),
        accessor: 'Debit',
  
        Cell: ({cell: {value}, row}) => (
          <>
            <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
            {addCommas(value.toFixed(2))} {CURRENCY_APP}
            </span>
  
          </>
        ),
      },
      {
        Header: () => (<div className="w-100 text-end">Debit</div>),
        accessor: 'Credit',
  
        Cell: ({cell: {value}, row}) =>
        <>
        <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
        {addCommas(value.toFixed(2))} {CURRENCY_APP}
      </span>
      </>
      }
    
    
  
    ]

  // less than 420

  const columndata3: ReadonlyArray<
    Column<{
      GroupedDate: string
      Total_transactions: number
      TotalDebit: number
      TotalCredit: number
      Opening_Balance: number
      Closing_Balance: number 
      json_result: string 
    }>
    > = [
      {
        Header: () => null,
        id: 'expander',
  
        Cell: ({row}: any) => {
          return (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <>
                  <span className='svg-icon svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <g fill='none' fillRule='evenodd'>
                        <path d='M0 0L24 0 24 24 0 24z'></path>
                        <path
                          fill='#009ef7'
                          fillRule='nonzero'
                          d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                        ></path>
                      </g>
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  <span className='svg-icon svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <g fill='none' fillRule='evenodd'>
                        <path d='M0 0L24 0 24 24 0 24z'></path>
                        <path
                          fill='#009ef7'
                          fillRule='nonzero'
                          d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                        ></path>
                      </g>
                    </svg>
                  </span>
                </>
              )}
            </span>
          )
        },
      },
      {
        Header: 'json_result',
        accessor: 'json_result'
      },
      {
        Header: 'Date',
        accessor: 'GroupedDate',
  
        Cell: ({cell: {value}}) => (
          <>
            <span className='text-dark text-nowrap fw-bolder d-block mb-1 fs-6'>
              {dayjs(value).format('LL')}
            </span>
            <span className='text-muted  text-nowrap fw-bold text-muted d-block fs-7'>
              {dayjs(value).format('dddd')}
            </span>
          </>
        ),
      },
      
      {
        Header: () => (<div className="w-100 text-end">C.Balance</div>),
        accessor: 'Closing_Balance',
  
        Cell: ({cell: {value}, row}) =>
        <>
        <span className='text-primary text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
        {addCommas(value.toFixed(2))} {CURRENCY_APP}
      </span>
      </>
      }
  
    ]

  const columndata4: ReadonlyArray<
    Column<{
      Narration: string,
      Debit: number,
      Credit: number,
      Balance: number,
      Project: number,
      Datereport: string
    }>
    > = [
      {
        Header: 'Project',
        accessor: 'Project'
      },
      {
        Header: 'Item',
        accessor: 'Narration',
  
        Cell: ({cell: {value}}) => (
          <>
           {value === "A DEBIT WAS MADE TO THE ACCOUNT" ?
            <span className='text-success text-nowrap fw-bold d-block fs-7'>
              {"A CREDIT WAS MADE TO THE ACCOUNT"}
            </span>
                  :
                  <span className='text-nowrap fw-bold  d-block fs-7 '>
                  {value}
                </span>   }
          </>
        ),
      }
    
    ]

  //less than 420

  //less than 730 but more than 420
  const columndata5: ReadonlyArray<
    Column<{
      GroupedDate: string
      Total_transactions: number
      TotalDebit: number
      TotalCredit: number
      Opening_Balance: number
      Closing_Balance: number  
      json_result: string
    }>
    > = [
      {
        Header: () => null,
        id: 'expander',
  
        Cell: ({row}: any) => {
          return (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <>
                  <span className='svg-icon svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <g fill='none' fillRule='evenodd'>
                        <path d='M0 0L24 0 24 24 0 24z'></path>
                        <path
                          fill='#009ef7'
                          fillRule='nonzero'
                          d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                        ></path>
                      </g>
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  <span className='svg-icon svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <g fill='none' fillRule='evenodd'>
                        <path d='M0 0L24 0 24 24 0 24z'></path>
                        <path
                          fill='#009ef7'
                          fillRule='nonzero'
                          d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                        ></path>
                      </g>
                    </svg>
                  </span>
                </>
              )}
            </span>
          )
        },
      },
      {
        Header: 'json_result',
        accessor: 'json_result'
      },
      {
        Header: 'Date',
        accessor: 'GroupedDate',
  
        Cell: ({cell: {value}}) => (
          <>
            <span className='text-dark text-nowrap fw-bolder d-block mb-1 fs-6'>
              {dayjs(value).format('LL')}
            </span>
            <span className='text-muted  text-nowrap fw-bold text-muted d-block fs-7'>
              {dayjs(value).format('dddd')}
            </span>
          </>
        ),
      },
      {
        Header: () => (<div className="w-100 text-end">T.Count</div>),
        accessor: 'Total_transactions',
  
        Cell: ({cell: {value}, row}) => (
          <>
            <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
            {value}
            </span>
  
          </>
        ),
      },
      {
        Header: () => (<div className="w-100 text-end">O.Balance</div>),
        accessor: 'Opening_Balance',
  
        Cell: ({cell: {value}, row}) =>
        <>
        <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
        {addCommas(value.toFixed(2))} {CURRENCY_APP}
      </span>
      </>
      },
      {
        Header: () => (<div className="w-100 text-end">C.Balance</div>),
        accessor: 'Closing_Balance',
  
        Cell: ({cell: {value}, row}) =>
        <>
        <span className='text-primary text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
        {addCommas(value.toFixed(2))} {CURRENCY_APP}
      </span>
      </>
      }
  
    ]
  const columndata6: ReadonlyArray<
    Column<{
      Narration: string,
      Debit: number,
      Credit: number,
      Balance: number,
      Project: number,
      Datereport: string
    }>
    > = [
      {
        Header: 'Project',
        accessor: 'Project'
      },
      {
        Header: 'Time',
        accessor: 'Datereport',
  
        Cell: ({cell: {value}, row}) => (
          <>
            <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6'>
            {dayjs(value).format('LT')}
            </span>
  
          </>
        ),
      },
      {
        Header: 'Item',
        accessor: 'Narration',
  
        Cell: ({cell: {value}}) => (
          <>
           {value === "A DEBIT WAS MADE TO THE ACCOUNT" ?
            <span className='text-success text-nowrap fw-bold d-block fs-7'>
              {"A CREDIT WAS MADE TO THE ACCOUNT"}
            </span>
                  :
                  <span className='text-nowrap fw-bold  d-block fs-7 '>
                  {value}
                </span>   }
          </>
        ),
      },
      {
        Header: () => (<div className="w-100 text-end">Credit</div>),
        accessor: 'Debit',
  
        Cell: ({cell: {value}, row}) => (
          <>
            <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
            {addCommas(value.toFixed(2))} {CURRENCY_APP}
            </span>
  
          </>
        ),
      },
      {
        Header: () => (<div className="w-100 text-end">Debit</div>),
        accessor: 'Credit',
  
        Cell: ({cell: {value}, row}) =>
        <>
        <span className='text-dark text-nowrap  fw-bolder d-block mb-1 fs-6 text-end'>
        {addCommas(value.toFixed(2))} {CURRENCY_APP}
      </span>
      </>
      }
    
    ]
  //less than 730 but more than 420






  useEffect(() => {
    if (balancesissuccess && creditissuccess && debitissuccess) {
      setStatusnow(true)
    } else {
      setStatusnow(false)
    }
  }, [balancesissuccess, creditissuccess, debitissuccess])

  useEffect(() => {
    if (
      statusnow &&
      !creditisRefetching &&
      !debitisRefetching &&
      !balanceisRefetching
    ) {
      setStatusnow2(true)
    } else {
      setStatusnow2(false)
    }
  }, [creditisRefetching, creditisRefetching, balanceisRefetching, statusnow])

  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Transactions</PageTitle>
      {/* begin::Row */}
      {/* begin::Row */}

      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}

        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='120px'
            cardName='Balance'
            chartName='Balance'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...balancesnew?.map((x: any) => x.Closing_Balance)))}
            chartCategories={balancesnew?.sort((a: any, b: any) => (a.GroupedDate > b.GroupedDate ? 1 : -1))
              .map((x: any) => dayjs(x.GroupedDate).format('LL'))}
            chartData={balancesnew?.sort((a: any, b: any) => (a.GroupedDate > b.GroupedDate ? 1 : -1))
              .map((x: any) => x.Closing_Balance)}
            values={
              <>
                <div className='fs-2 fw-bolder d-block '>
                  <CountUp
                    className='text-primary'
                    end={
                      balancesnew?.sort((a: any, b: any) =>
                          a.GroupedDate > b.GroupedDate ? -1 : 1
                        )
                        .map((x: any) => x.Closing_Balance)[0]
                    }
                    duration={2}
                    decimals={2}
                    separator=','
                  />{' '}
                  <sup className='fs-6 text-primary'>{CURRENCY_APP}</sup>
                </div>
              </>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {balancesnew?.sort((a: any, b: any) =>
                          a.GroupedDate > b.GroupedDate ? -1 : 1
                        )
                        .map((x: any) => x.GroupedDate)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                balancesnew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.GroupedDate)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                balancesnew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.Closing_Balance)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {balancesnew?.sort((a: any, b: any) =>
                          a.GroupedDate > b.GroupedDate ? -1 : 1
                        )
                        .map((x: any) => x.GroupedDate)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                balancesnew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.GroupedDate)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                balancesnew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.Closing_Balance)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}
{/* begin::Col */}
<div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='success'
            chartHeight='120px'
            cardName='Credit'
            chartName='Credit'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...debitdatanew?.map((x: any) => x.TotalDebit)))}
            chartCategories={debitdatanew?.sort((a: any, b: any) => (a.GroupedDate > b.GroupedDate ? 1 : -1))
              .map((x: any) => dayjs(x.GroupedDate).format('LL'))}
            chartData={debitdatanew?.sort((a: any, b: any) => (a.GroupedDate > b.GroupedDate ? 1 : -1))
              .map((x: any) => x.TotalDebit)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-success'
                  end={
                    debitdatanew?.sort((a: any, b: any) =>
                        a.GroupedDate > b.GroupedDate ? -1 : 1
                      )
                      .map((x: any) => x.TotalDebit)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-success fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {debitdatanew?.sort((a: any, b: any) =>
                          a.GroupedDate > b.GroupedDate ? -1 : 1
                        )
                        .map((x: any) => x.GroupedDate)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                                debitdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.GroupedDate)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                debitdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalDebit)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {debitdatanew?.sort((a: any, b: any) =>
                          a.GroupedDate > b.GroupedDate ? -1 : 1
                        )
                        .map((x: any) => x.GroupedDate)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                               debitdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.GroupedDate)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                debitdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalDebit)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='120px'
            cardName='Debit'
            chartName='Debit'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...creditdatanew?.map((x: any) => x.TotalCredit)))}
            chartCategories={creditdatanew?.sort((a: any, b: any) => (a.GroupedDate > b.GroupedDate ? 1 : -1))
              .map((x: any) => dayjs(x.GroupedDate).format('LL'))}
            chartData={creditdatanew?.sort((a: any, b: any) => (a.GroupedDate > b.GroupedDate ? 1 : -1))
              .map((x: any) => x.TotalCredit)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-danger'
                  end={
                    creditdatanew?.sort((a: any, b: any) =>
                        a.GroupedDate > b.GroupedDate ? -1 : 1
                      )
                      .map((x: any) => x.TotalCredit)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-danger fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {creditdatanew?.sort((a: any, b: any) =>
                          a.GroupedDate > b.GroupedDate ? -1 : 1
                        )
                        .map((x: any) => x.GroupedDate)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-danger'>
                              {dayjs(
                                creditdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.GroupedDate)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                creditdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalCredit)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {creditdatanew?.sort((a: any, b: any) =>
                          a.GroupedDate > b.GroupedDate ? -1 : 1
                        )
                        .map((x: any) => x.GroupedDate)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-danger'>
                              {dayjs(
                                creditdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.GroupedDate)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                creditdatanew?.sort((a: any, b: any) =>
                                    a.GroupedDate > b.GroupedDate ? -1 : 1
                                  )
                                  .map((x: any) => x.TotalCredit)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}

        
      </div>
      {/* end::Row */}
      <div className='row g-5 gx-xxl-8'>
    
        <div className='col-xxl-12'>
          <KTCard>
            <KTCardBody className='py-4'>
              <div className='row mt-4'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                  <div className='mb-2'>
                    <h3 className='fw-bolder my-2'>Card Transactions</h3>
                  </div>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                      <a href='#' onClick={handlesearchKeyDown2}>
                        <KTSVG
                          path='/media/icons/duotune/general/gen021.svg'
                          className='svg-icon-1 position-absolute me-4 top-0 end-0 mt-3 text-primary'
                        />
                      </a>
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Transactions'
                        ref={myRefsearch}
                        // value={searchTitle}
                        onChange={handleinputs}
                        onKeyDown={handlesearchKeyDown}
                      />
                    </div>
                    {/* end::Search */}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    
                  </div>
                </div>
              </div>

              <Maintable
                tabledata={alltransactionissuccess ? tabledata : []}
                startlimit={startlimit}
                hiddencolumnsmade={hiddencolumnsmade}
                hiddencolumnsmade2={hiddencolumnsmade2}
                setstartlimit={setStartlimit}
                paginationsize={paginationsize}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPaginationsize={setPaginationsize}
                movetofirstpage={movetofirstpage}
                setMovetofirstpage={setMovetofirstpage}
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : columndata1
                }
                tabledata2={tabledata}
                columndata2={
                  onlyWidth <= 576
                    ? columndata4
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata6
                    : columndata2
                }
                table1loaded={alltransactionsrefetching}
                table2loaded={alltransactionsrefetching}
                tablename={tablename}
                tablelength={alltransactionsstatus === 'success' ? tabledatalength : 0}
                filteredsubdata={filteredsubdata}
                filteredtablesubData={filteredtablesubData}
                setServersort={setServersort}
              />
            </KTCardBody>
          </KTCard>
        </div>
      </div>
     
    

    </>
  )
}

export default Home