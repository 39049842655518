import React, { ReactNode, createContext, useContext, useState } from "react";

type MyBioData = {
modalUpdatepassword: boolean;
setModalUpdatepassword: React.Dispatch<React.SetStateAction<boolean>>;
modalpasswordchange: boolean;
setModalPasswordchange: React.Dispatch<React.SetStateAction<boolean>>;
 
};

type MyBioProviderProps = {
  children: ReactNode;
};

const MyBiodataContext = createContext<MyBioData | undefined>(undefined);

export const useBiodataContext = (): MyBioData => {
  const context = useContext(MyBiodataContext);

  if (!context) {
    throw new Error(
      "useBiodataContext must be used within a MyBioProvider"
    );
  }

  return context;
};

export const MyBiodataProvider: React.FC<MyBioProviderProps> = ({
  children,
}) => {
  

  const [modalUpdatepassword, setModalUpdatepassword] = useState(false)
  const [modalpasswordchange, setModalPasswordchange] = useState(false)
  const contextValue: MyBioData = {
    
    modalUpdatepassword,
    setModalUpdatepassword,
    modalpasswordchange,
    setModalPasswordchange
   
  };

  return (
    <MyBiodataContext.Provider value={contextValue}>
      {children}
    </MyBiodataContext.Provider>
  );
};