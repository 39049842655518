import React, {FC, useEffect, useState, useCallback, useRef} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import axios from 'axios'
import {useTable, useSortBy} from 'react-table'
import {Tableloading} from './Tableloading'
import CountUp from 'react-countup'


type Props = {
  rowdata: any
  tabledata2: []
  columndata2: any
  tablename: string
  filteredsubdata: any
  paymenttypedetails?: any
  table2loaded: boolean | undefined
  table1loaded: boolean | undefined
  filteredtablesubData: any
  hiddencolumnsmade2: any
}
const API_URL = process.env.REACT_APP_API_URL
const CustomTableStyle: React.CSSProperties = {
  borderCollapse: 'collapse',
  borderSpacing: 0,
 
};

const CustomTableRowStyle: React.CSSProperties = {
  border: 'none',
  margin: 0,
  padding: 0,
};

const CustomTableCellStyle: React.CSSProperties = {
  border: 'none',
  margin: 0,
  padding: '0.1rem 0.1rem',
};
const Mainsubtable: React.FC<Props> = ({
  rowdata,
  tabledata2,
  columndata2,
  hiddencolumnsmade2,
  tablename,
  filteredsubdata,
  paymenttypedetails,
  table2loaded,
  table1loaded,
  filteredtablesubData,
}) => {
  const CURRENCY_APP = process.env.REACT_APP_CURRENCY

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state} = useTable(
    {
      columns: columndata2 as any,
      data: filteredtablesubData,

      defaultColumn: {},
      initialState: {
        hiddenColumns: hiddencolumnsmade2
      }
    },
    useSortBy
  )


  return (
    <>
      <div className='card bg-light'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-5 mb-1'>{tablename}</span>

            {typeof filteredsubdata != 'number' ? (
              <>
                <></>
              </>
            ) : (
              <span className='text-muted mt-1 fw-bold fs-7'>
                {filteredsubdata === 1
                  ? `${filteredsubdata} Transaction`
                  : `${filteredsubdata} Transactions`}
              </span>
            )}
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
                {/* add side items here*/}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <div className='card-body py-3'>
       
          {typeof filteredsubdata == 'number' ? (
            <div className='table-responsive'>
              <table
                {...getTableProps()}
                className='table align-middle table-row-dashed table-row-gray-300 fs-6 gy-5 dataTable no-footer '
              >
                <thead>
                  {headerGroups.map((headerGroup, keys) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className='text-start text-muted fw-bolder fs-7 border-bottom border-gray-200 text-uppercase gs-0'
                      key={keys + Date.now()}
                    >
                      {headerGroup.headers.map((column: any, key) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={key + Date.now()}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <>
                                  <span className='svg-icon svg-icon-primary svg-icon-1x'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                    >
                                      <g fill='none' fillRule='evenodd'>
                                        <path d='M0 0L24 0 24 24 0 24z'></path>
                                        <path
                                          fill='#009ef7'
                                          fillRule='nonzero'
                                          d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className='svg-icon svg-icon-primary svg-icon-1x'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                    >
                                      <g fill='none' fillRule='evenodd'>
                                        <path d='M0 0L24 0 24 24 0 24z'></path>
                                        <path
                                          fill='#009ef7'
                                          fillRule='nonzero'
                                          d='M6.707 15.707a1 1 0 11-1.414-1.414l6-6a1 1 0 011.383-.03l6 5.5a1 1 0 11-1.352 1.474l-5.294-4.853-5.323 5.323z'
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                </>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className='text-gray-600 fw-bold'>
                  
                    <>
                      {rows.length > 0 ? (
                        rows.map((row, morekeys) => {
                          prepareRow(row)
                          return (
                            <React.Fragment key={row.getRowProps().key}>
                              <tr {...row.getRowProps()} key={morekeys + Date.now()}>
                                {row.cells.map((cell: any, key) => {
                                  return (
                                    <td {...cell.getCellProps()} key={key + Date.now()}>
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                })}
                              </tr>
                            </React.Fragment>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={columndata2.length}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                              No matching records found
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  
                </tbody>
              </table>
            </div>
          ) : (
            <div className='mb-5'>
              <div dangerouslySetInnerHTML={{__html: filteredtablesubData}} />
            </div>
          )}

          {/* end::Table container */}
        </div>

        {/* begin::Body */}
      </div>

  
    </>
  )
}

export {Mainsubtable}
