import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useBiodata, useUpdatebiodata2} from '../contextProviders/Transactionsprovider'
import {useAuth} from '../../app/modules/auth'
import Select from 'react-select'
import {useSelectStyles} from '../helpers/useSelectStyles'
import {Upadatesuccessful} from './modals/Upadatesuccessful'
import {nationalityOptions} from '../helpers/useNationalities'
import dayjs from 'dayjs'

const Biodatainfo = () => {
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState<any>(null)
  const [bioitemstoupdate, setBioitemstoupdate] = useState<any>({})
  const [isRefetchComplete, setIsRefetchComplete] = useState(false)
  const [isRefetchCompletemodal, setIsRefetchCompletemodal] = useState(false)
  const [HasKids, setHasKids] = useState<boolean | null>(null)
  const formatDate = (dateString: any) => {
    if (!dateString) return ''
    return dayjs(dateString).format('YYYY-MM-DD')
  }
  const selectStyles = useSelectStyles()
  const initialValues = {
    Name: '',
    IdPassportNo: '',
    PhoneNo: '',
    Email: '',
    Gender: '',
    Marriage_Status: '',
    DateOfBirth: '',
    Nationality: '',
    HomeAddress: '',
    HasKids: false,
    children: [{fullName: '', dateOfBirth: ''}],
    AreaOfResidence: '',
    Occupation: '',
    CompanyName: '',
    CompanyAddress: '',
    CompanyPhoneNumber: '',
    SpouseName: '',
    SpouseDateOfBirth: '',
    SpouseOccupation: '',
    SpouseCompany: '',
    SpouseTelOffice: '',
    SpouseNationality: '',
    SpouseIdPassportNo: '',
    SpousePosition: '',
    SpouseCompanyAddress: '',
    SpousePhoneNo: '',
    SpouseEmailAddress: '',
    SpouseMemberNumber: '',
    NextOfKinName: '',
    NextOfKinRelationship: '',
    NextOfKinTelephone: '',
    NextOfKinEmail: '',
  }

  const profileDetailsSchema = Yup.object().shape({
    Name: Yup.string().required('Full name is required'),
    IdPassportNo: Yup.string().required('ID Number is required'),
    PhoneNo: Yup.string().required('Phone number is required'),
    Gender: Yup.string().required('Gender is required'),
    Email: Yup.string().required('Email is required').email('Invalid email format'),
    Marriage_Status: Yup.string().required('Marital status is required'),
    DateOfBirth: Yup.date().required('Date of Birth is required'),
    Nationality: Yup.string().required('Nationality is required'),
    HomeAddress: Yup.string().required('Home Address is required'),
    AreaOfResidence: Yup.string().required('Area of Residence is required'),
    Occupation: Yup.string().required('Occupation is required'),
    CompanyName: Yup.string().required('Company Name is required'),
    CompanyAddress: Yup.string().required('Company Address is required'),
    CompanyPhoneNumber: Yup.string().required('Phone Number (Ofiice) is required'),
    HasKids: Yup.boolean().required('Please select if you have kids or not'),
    // Spouse fields with conditional validation
    SpouseName: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Name is required'),
      otherwise: Yup.string(),
    }),
    SpouseDateOfBirth: Yup.date().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.date().required('Spouse Date of Birth is required'),
      otherwise: Yup.date(),
    }),
    SpouseOccupation: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Occupation is required'),
      otherwise: Yup.string(),
    }),
    SpouseCompany: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Company is required'),
      otherwise: Yup.string(),
    }),
    SpouseTelOffice: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Phone Number (Office) is required'),
      otherwise: Yup.string(),
    }),
    SpouseNationality: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Nationality is required'),
      otherwise: Yup.string(),
    }),
    SpouseIdPassportNo: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse ID No. / Passport No. is required'),
      otherwise: Yup.string(),
    }),
    SpouseCompanyAddress: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Company Address is required'),
      otherwise: Yup.string(),
    }),
    SpousePhoneNo: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Phone No. is required'),
      otherwise: Yup.string(),
    }),
    SpouseEmailAddress: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Email Address is required').email('Invalid email format'),
      otherwise: Yup.string().email('Invalid email format'),
    }),
    SpouseMemberNumber: Yup.string().when('Marriage_Status', {
      is: (val: any) => val !== 'SINGLE',
      then: Yup.string().required('Spouse Member Number is required'),
      otherwise: Yup.string(),
    }),

    // Children array
    children: Yup.array().of(
      Yup.object().shape({
        fullName: Yup.string().required("Child's name is required"),
        dateOfBirth: Yup.date()
          .required("Child's date of birth is required")
          .max(new Date(), "Date of birth can't be in the future")
          .test('is-under-25', 'Child must be under 25 years old', function (value) {
            if (!value) return true
            const cutoffDate = new Date()
            cutoffDate.setFullYear(cutoffDate.getFullYear() - 25)
            return value > cutoffDate
          }),
      })
    ),

    // Next of Kin fields
    NextOfKinName: Yup.string().required('Next of Kin Name is required'),
    NextOfKinRelationship: Yup.string().required('Next of Kin Relationship is required'),
    NextOfKinTelephone: Yup.string().required('Next of Kin PhoneNo is required'),
    NextOfKinEmail: Yup.string()
      .required('Next of Kin Email is required')
      .email('Invalid email format'),
  })

  const {currentUser} = useAuth()

  const {
    isLoading: allbiodataisloading,
    isSuccess: allbiodataissuccess,
    data: allbiodatadata,
    isPreviousData: allbiodataispreviousdata,
    status: allbiodatastatus,
    refetch: allbiodatarefetch,
  } = useBiodata(Number(currentUser?.member_id))

  const [loading, setLoading] = useState(false)
  const {mutateAsync: updateBiodatamutateAsync} = useUpdatebiodata2(bioitemstoupdate)

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values: any, {setStatus, setSubmitting}) => {
      const bioRecord = allbiodatadata?.data.biorecords

      const updatePayload: any = {Membership_No: bioRecord?.Membership_No}
      let isChanged = false

      Object.keys(values).forEach((key) => {
        if (key === 'children') {
          // Detailed comparison for children array
          const oldChildren = bioRecord[key] || []
          const newChildren = values[key] || []

          if (oldChildren.length !== newChildren.length) {
            updatePayload[key] = newChildren
            isChanged = true
          } else {
            // Compare each child's details
            for (let i = 0; i < newChildren.length; i++) {
              if (
                !oldChildren[i] ||
                oldChildren[i].fullName !== newChildren[i].fullName ||
                oldChildren[i].dateOfBirth !== newChildren[i].dateOfBirth
              ) {
                updatePayload[key] = newChildren
                isChanged = true
                break
              }
            }
          }
        } else if (values[key] !== bioRecord[key]) {
          updatePayload[key] = values[key]
          isChanged = true
        }
      })

      if (isChanged) {
        console.log('Submitting updates:', updatePayload)
        setBioitemstoupdate(updatePayload)
        setLoading(true)
      } else {
        setStatus('No changes to update.')
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (Object.keys(bioitemstoupdate).length > 0) {
      updateBiodatamutateAsync(bioitemstoupdate, {
        onSuccess: () => {
          setLoading(false)
          allbiodatarefetch().then(() => setIsRefetchComplete(true))
          setIsRefetchCompletemodal(true)
        },
        onError: (error: any) => {
          console.error('API Error: ', error)
          formik.setStatus('There is an error updating the Client Item.')
          setLoading(false)
          setBioitemstoupdate({})
        },
      })
    }
  }, [bioitemstoupdate])

  const maritalOptions = [
    {value: 'FULL MARRIED', label: 'FULL MARRIED'},
    {value: 'SINGLE', label: 'SINGLE'},
  ]

  useEffect(() => {
    if (selectedMaritalStatus && selectedMaritalStatus.value === 'SINGLE') {
      formik.setValues({
        ...formik.values,
        SpouseName: '',
        SpouseDateOfBirth: '',
        SpouseOccupation: '',
        SpouseCompany: '',
        SpouseTelOffice: '',
        SpouseNationality: '',
        SpouseIdPassportNo: '',
        SpousePosition: '',
        SpouseCompanyAddress: '',
        SpousePhoneNo: '',
        SpouseEmailAddress: '',
      })
    }
  }, [selectedMaritalStatus])

  useEffect(() => {
    if (allbiodatadata?.data.biorecords && formik.setValues) {
      const bioRecord = allbiodatadata.data.biorecords
      const transformedChildren = bioRecord.children.map((child: any) => ({
        fullName: child.FullName,
        dateOfBirth: child.DateOfBirth,
      }))
   
      setHasKids(bioRecord.HasKids)

      formik.setValues({
        Name: bioRecord.Name || '',
        IdPassportNo: bioRecord.IdPassportNo || '',
        PhoneNo: bioRecord.PhoneNo || '',
        Gender: bioRecord.Gender || '',
        Email: bioRecord.Email || '',
        Marriage_Status: bioRecord.Marriage_Status || '',
        DateOfBirth: formatDate(bioRecord.DateOfBirth) || '',
        Nationality: bioRecord.Nationality || '',
        HomeAddress: bioRecord.HomeAddress || '',
        HasKids: Boolean(bioRecord.HasKids),
        children: Boolean(bioRecord.HasKids) ? bioRecord.children.map((child: any) => ({
          fullName: child.FullName,
          dateOfBirth: child.DateOfBirth,
        })) : [],
        AreaOfResidence: bioRecord.AreaOfResidence || '',
        Occupation: bioRecord.Occupation || '',
        CompanyName: bioRecord.CompanyName || '',
        CompanyAddress: bioRecord.CompanyAddress || '',
        CompanyPhoneNumber: bioRecord.CompanyPhoneNumber || '',
        SpouseName: bioRecord.SpouseName || '',
        SpouseDateOfBirth: formatDate(bioRecord.SpouseDateOfBirth) || '',
        SpouseOccupation: bioRecord.SpouseOccupation || '',
        SpouseCompany: bioRecord.SpouseCompany || '',
        SpouseTelOffice: bioRecord.SpouseTelOffice || '',
        SpouseNationality: bioRecord.SpouseNationality || '',
        SpouseIdPassportNo: bioRecord.SpouseIdPassportNo || '',
        SpousePosition: bioRecord.SpousePosition || '',
        SpouseCompanyAddress: bioRecord.SpouseCompanyAddress || '',
        SpousePhoneNo: bioRecord.SpousePhoneNo || '',
        SpouseEmailAddress: bioRecord.SpouseEmailAddress || '',
        SpouseMemberNumber: bioRecord.SpouseMemberNumber || '',
        NextOfKinName: bioRecord.NextOfKinName || '',
        NextOfKinRelationship: bioRecord.NextOfKinRelationship || '',
        NextOfKinTelephone: bioRecord.NextOfKinTelephone || '',
        NextOfKinEmail: bioRecord.NextOfKinEmail || '',
      })
      
      const maritalStatusOption = maritalOptions.find(
        (option) => option.value === bioRecord.Marriage_Status
      )
      setSelectedMaritalStatus(maritalStatusOption || null)
    }
  }, [allbiodatadata, formik.setValues])

  useEffect(() => {
    if (isRefetchComplete) {
      const bioRecord = allbiodatadata?.data.biorecords
      const maritalStatusOption = maritalOptions.find(
        (option) => option.value === bioRecord.Marriage_Status
      )
      setSelectedMaritalStatus(maritalStatusOption || null)
      const transformedChildren = bioRecord.children.map((child: any) => ({
        fullName: child.FullName,
        dateOfBirth: child.DateOfBirth,
      }))
      formik.setValues({
        Name: bioRecord.Name || '',
        IdPassportNo: bioRecord.IdPassportNo || '',
        PhoneNo: bioRecord.PhoneNo || '',
        Gender: bioRecord.Gender || '',
        Email: bioRecord.Email || '',
        Marriage_Status: bioRecord.Marriage_Status || '',
        DateOfBirth: formatDate(bioRecord.DateOfBirth) || '',
        Nationality: bioRecord.Nationality || '',
        HomeAddress: bioRecord.HomeAddress || '',
        HasKids: Boolean(bioRecord.HasKids),
        children: Boolean(bioRecord.HasKids) ? bioRecord.children.map((child: any) => ({
          fullName: child.FullName,
          dateOfBirth: child.DateOfBirth,
        })) : [],
        AreaOfResidence: bioRecord.AreaOfResidence || '',
        Occupation: bioRecord.Occupation || '',
        CompanyName: bioRecord.CompanyName || '',
        CompanyAddress: bioRecord.CompanyAddress || '',
        CompanyPhoneNumber: bioRecord.CompanyPhoneNumber || '',
        SpouseName: bioRecord.SpouseName || '',
        SpouseDateOfBirth: formatDate(bioRecord.SpouseDateOfBirth) || '',
        SpouseOccupation: bioRecord.SpouseOccupation || '',
        SpouseCompany: bioRecord.SpouseCompany || '',
        SpouseTelOffice: bioRecord.SpouseTelOffice || '',
        SpouseNationality: bioRecord.SpouseNationality || '',
        SpouseIdPassportNo: bioRecord.SpouseIdPassportNo || '',
        SpousePosition: bioRecord.SpousePosition || '',
        SpouseCompanyAddress: bioRecord.SpouseCompanyAddress || '',
        SpousePhoneNo: bioRecord.SpousePhoneNo || '',
        SpouseEmailAddress: bioRecord.SpouseEmailAddress || '',
        SpouseMemberNumber: bioRecord.SpouseMemberNumber || '',
        NextOfKinName: bioRecord.NextOfKinName || '',
        NextOfKinRelationship: bioRecord.NextOfKinRelationship || '',
        NextOfKinTelephone: bioRecord.NextOfKinTelephone || '',
        NextOfKinEmail: bioRecord.NextOfKinEmail || '',
      })
      setIsRefetchComplete(false)
      setHasKids(bioRecord.HasKids)
    }
  }, [isRefetchComplete, allbiodatadata, formik.setValues])

  useEffect(() => {
    const hasChildren =
      formik.values.children.length > 0 &&
      formik.values.children.some((child: any) => child.fullName || child.dateOfBirth)
    formik.setFieldValue('HasKids', hasChildren)
  }, [formik.values.children])
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/* Avatar section */}
              <div className='row mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl('/media/avatars/avatar.svg')})`,
                    }}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/avatars/avatar.svg')})`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              {/* Full Name and ID Number */}
              <>
                {/* Basic Personal Information */}
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Full Name
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Full Name'
                      {...formik.getFieldProps('Name')}
                      disabled
                      style={{
                        backgroundColor: '#f5f5f5',
                        color: '#a1a1a1',
                        cursor: 'not-allowed',
                      }}
                    />
                    {formik.touched.Name && formik.errors.Name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Name}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      ID No./ Passport No.
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Id Number'
                      {...formik.getFieldProps('IdPassportNo')}
                      disabled
                      style={{
                        backgroundColor: '#f5f5f5',
                        color: '#a1a1a1',
                        cursor: 'not-allowed',
                      }}
                    />
                    {formik.touched.IdPassportNo && formik.errors.IdPassportNo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.IdPassportNo}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Date of Birth and Nationality */}
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Date of Birth
                    </label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid'
                      {...formik.getFieldProps('DateOfBirth')}
                    />
                    {formik.touched.DateOfBirth && formik.errors.DateOfBirth && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.DateOfBirth}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Nationality
                    </label>
                    <Select
                      options={nationalityOptions}
                      value={nationalityOptions.find(
                        (option) => option.value === formik.values.Nationality
                      )}
                      onChange={(option: any) => formik.setFieldValue('Nationality', option.value)}
                      placeholder='Select Nationality ...'
                      styles={selectStyles}
                    />
                    {formik.touched.Nationality && formik.errors.Nationality && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Nationality}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Contact Information */}
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Phone Number
                    </label>
                    <input
                      type='tel'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Phone number'
                      {...formik.getFieldProps('PhoneNo')}
                    />
                    {formik.touched.PhoneNo && formik.errors.PhoneNo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.PhoneNo}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Gender</label>
                    <Select
                      options={[
                        {value: 'MALE', label: 'MALE'},
                        {value: 'FEMALE', label: 'FEMALE'},
                      ]}
                      value={
                        formik.values.Gender
                          ? {value: formik.values.Gender, label: formik.values.Gender}
                          : null
                      }
                      onChange={(option: any) => formik.setFieldValue('Gender', option.value)}
                      placeholder='Select Gender ...'
                      styles={selectStyles}
                    />
                    {formik.touched.Gender && formik.errors.Gender && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Gender}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Address Information */}
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Email</label>
                    <input
                      type='email'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Email'
                      {...formik.getFieldProps('Email')}
                    />
                    {formik.touched.Email && formik.errors.Email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Email}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Area of Residence
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Area of Residence'
                      {...formik.getFieldProps('AreaOfResidence')}
                    />
                    {formik.touched.AreaOfResidence && formik.errors.AreaOfResidence && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.AreaOfResidence}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Personal Status */}
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Home Address
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Home Address'
                      {...formik.getFieldProps('HomeAddress')}
                    />
                    {formik.touched.HomeAddress && formik.errors.HomeAddress && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.HomeAddress}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Marital Status
                    </label>
                    <Select
                      options={maritalOptions}
                      value={selectedMaritalStatus}
                      onChange={(option: any) => {
                        setSelectedMaritalStatus(option)
                        formik.setFieldValue('Marriage_Status', option ? option.value : '')
                      }}
                      placeholder='Select Marital Status ...'
                      styles={selectStyles}
                      isSearchable={true}
                      escapeClearsValue
                      backspaceRemovesValue
                      onBlur={formik.handleBlur('Marriage_Status')}
                    />
                    {formik.touched.Marriage_Status && formik.errors.Marriage_Status && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.Marriage_Status}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Company Information */}
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Kids</label>
                    <Select
                      options={[
                        {value: false, label: 'NO KIDS'},
                        {value: true, label: 'HAS KIDS'},
                      ]}
                      value={{
                        value: HasKids,
                        label: HasKids ? 'HAS KIDS' : 'NO KIDS',
                      }}
                      onChange={(option: any) => {
                        setHasKids(option.value)
                        formik.setFieldValue('HasKids', option.value)
                        if (!option.value) {
                          formik.setFieldValue('children', [])
                        } else if (formik.values.children.length === 0) {
                          formik.setFieldValue('children', [{fullName: '', dateOfBirth: ''}])
                        }
                      }}
                      placeholder='Select Kids Status ...'
                      styles={selectStyles}
                      isSearchable={false}
                      escapeClearsValue
                      backspaceRemovesValue
                      onBlur={formik.handleBlur('HasKids')}
                    />
                    {formik.touched.HasKids && formik.errors.HasKids && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.HasKids}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Occupation
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Occupation'
                      {...formik.getFieldProps('Occupation')}
                    />
                    {formik.touched.Occupation && formik.errors.Occupation && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Occupation}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Company Name
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Company Name'
                      {...formik.getFieldProps('CompanyName')}
                    />
                    {formik.touched.CompanyName && formik.errors.CompanyName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.CompanyName}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Company Address
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Company Address'
                      {...formik.getFieldProps('CompanyAddress')}
                    />
                    {formik.touched.CompanyAddress && formik.errors.CompanyAddress && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.CompanyAddress}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Phone Number (Office)
                    </label>
                    <input
                      type='tel'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Phone Number (Office)'
                      {...formik.getFieldProps('CompanyPhoneNumber')}
                    />
                    {formik.touched.CompanyPhoneNumber && formik.errors.CompanyPhoneNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.CompanyPhoneNumber}</div>
                      </div>
                    )}
                  </div>
                </div>
              </>
              {selectedMaritalStatus && selectedMaritalStatus.value !== 'SINGLE' && (
                <>
                  <h3 className='fw-bolder m-0 mt-10'>Spouse Details</h3>
                  <hr className='my-4 border-gray-400' />

                  {/* Basic Information */}
                  <div className='row mb-6'>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Name
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Name'
                        {...formik.getFieldProps('SpouseName')}
                      />
                      {formik.touched.SpouseName && formik.errors.SpouseName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseName}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse ID No. / Passport No.
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse ID No. / Passport No.'
                        {...formik.getFieldProps('SpouseIdPassportNo')}
                      />
                      {formik.touched.SpouseIdPassportNo && formik.errors.SpouseIdPassportNo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseIdPassportNo}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Date of Birth
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-lg form-control-solid'
                        {...formik.getFieldProps('SpouseDateOfBirth')}
                      />
                      {formik.touched.SpouseDateOfBirth && formik.errors.SpouseDateOfBirth && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseDateOfBirth}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Nationality
                      </label>
                      <Select
                        options={nationalityOptions}
                        value={nationalityOptions.find(
                          (option) => option.value === formik.values.SpouseNationality
                        )}
                        onChange={(option: any) =>
                          formik.setFieldValue('SpouseNationality', option.value)
                        }
                        placeholder='Select Spouse Nationality ...'
                        styles={selectStyles}
                      />
                      {formik.touched.SpouseNationality && formik.errors.SpouseNationality && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseNationality}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Contact Information */}
                  <div className='row mb-6'>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Phone No.
                      </label>
                      <input
                        type='tel'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Phone No.'
                        {...formik.getFieldProps('SpousePhoneNo')}
                      />
                      {formik.touched.SpousePhoneNo && formik.errors.SpousePhoneNo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpousePhoneNo}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Email Address
                      </label>
                      <input
                        type='email'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Email Address'
                        {...formik.getFieldProps('SpouseEmailAddress')}
                      />
                      {formik.touched.SpouseEmailAddress && formik.errors.SpouseEmailAddress && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseEmailAddress}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Occupation Details */}
                  <div className='row mb-6'>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Occupation
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Occupation'
                        {...formik.getFieldProps('SpouseOccupation')}
                      />
                      {formik.touched.SpouseOccupation && formik.errors.SpouseOccupation && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseOccupation}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Company
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Company'
                        {...formik.getFieldProps('SpouseCompany')}
                      />
                      {formik.touched.SpouseCompany && formik.errors.SpouseCompany && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseCompany}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Work Contact Information */}
                  <div className='row mb-6'>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Company Address
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Company Address'
                        {...formik.getFieldProps('SpouseCompanyAddress')}
                      />
                      {formik.touched.SpouseCompanyAddress && formik.errors.SpouseCompanyAddress && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseCompanyAddress}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Phone Number (Office)
                      </label>
                      <input
                        type='tel'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Phone Number (Office)'
                        {...formik.getFieldProps('SpouseTelOffice')}
                      />
                      {formik.touched.SpouseTelOffice && formik.errors.SpouseTelOffice && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseTelOffice}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-lg-6'>
                      <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                        Spouse Member Number
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Spouse Member Number'
                        {...formik.getFieldProps('SpouseMemberNumber')}
                      />
                      {formik.touched.SpouseMemberNumber && formik.errors.SpouseMemberNumber && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.SpouseMemberNumber}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* Continue with other spouse details... */}
              {HasKids ? (
                <>
                  <h3 className='fw-bolder m-0 mt-10'>Children Details (Under 25)</h3>
                  <hr className='my-4 border-gray-400' />
                  {formik.values.children.map((child: any, index: any) => (
                    <div key={index} className='row mb-6'>
                      <div className='col-lg-6'>
                        <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                          Child's Full Name
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder="Child's Full Name"
                          {...formik.getFieldProps(`children[${index}].fullName`)}
                        />
                        {formik.touched.children?.[index]?.fullName &&
                          formik.errors.children?.[index]?.fullName && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik.errors.children[index].fullName}
                              </div>
                            </div>
                          )}
                      </div>
                      <div className='col-lg-6'>
                        <div className='col-lg-12 d-flex justify-content-between align-items-center'>
                          <div>
                            <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                              Date of Birth
                            </label>
                          </div>
                          <div>
                            {index > 0 && (
                              <a
                                href='#'
                                className='text-danger'
                                onClick={(e) => {
                                  e.preventDefault()
                                  const newChildren = [...formik.values.children]
                                  newChildren.splice(index, 1)
                                  formik.setFieldValue('children', newChildren)
                                }}
                              >
                                Remove
                              </a>
                            )}
                          </div>
                        </div>

                        <input
                          type='date'
                          className='form-control form-control-lg form-control-solid'
                          {...formik.getFieldProps(`children[${index}].dateOfBirth`)}
                        />
                        {formik.touched.children?.[index]?.dateOfBirth &&
                          formik.errors.children?.[index]?.dateOfBirth && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik.errors.children[index].dateOfBirth}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                  <div className='row mb-6'>
                    <div className='col-lg-12 d-flex justify-content-end'>
                      <button
                        type='button'
                        className='btn btn-sm btn-light-primary'
                        onClick={() => {
                          formik.setFieldValue('children', [
                            ...formik.values.children,
                            {fullName: '', dateOfBirth: ''},
                          ])
                        }}
                      >
                        Add Child
                      </button>
                    </div>
                  </div>
                </>
              ): <></>}
              <h3 className='fw-bolder m-0 mt-10'>Next of Kin Details</h3>
              <hr className='my-4 border-gray-400' />

              {/* Next of Kin Name and Relationship */}
              <div className='row mb-6'>
                <div className='col-lg-6'>
                  <label className='col-lg-6 col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Next of Kin Name'
                    {...formik.getFieldProps('NextOfKinName')}
                  />
                  {formik.touched.NextOfKinName && formik.errors.NextOfKinName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.NextOfKinName}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6'>
                  <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                    Relationship
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Relationship'
                    {...formik.getFieldProps('NextOfKinRelationship')}
                  />
                  {formik.touched.NextOfKinRelationship && formik.errors.NextOfKinRelationship && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.NextOfKinRelationship}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Next of Kin PhoneNo and Email */}
              <div className='row mb-6'>
                <div className='col-lg-6'>
                  <label className='col-lg-6 col-form-label required fw-bold fs-6'>PhoneNo</label>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Next of Kin PhoneNo'
                    {...formik.getFieldProps('NextOfKinTelephone')}
                  />
                  {formik.touched.NextOfKinTelephone && formik.errors.NextOfKinTelephone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.NextOfKinTelephone}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6'>
                  <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                    Email address
                  </label>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Next of Kin Email'
                    {...formik.getFieldProps('NextOfKinEmail')}
                  />
                  {formik.touched.NextOfKinEmail && formik.errors.NextOfKinEmail && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.NextOfKinEmail}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {!loading && <span className='indicator-label'>Update Profile</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <Upadatesuccessful
        show={isRefetchCompletemodal}
        handleClose={() => setIsRefetchCompletemodal(false)}
      />
    </>
  )
}

export default Biodatainfo
