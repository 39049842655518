import React,{ useEffect, useState,Dispatch, SetStateAction} from 'react'
import ReactPaginate from 'react-paginate'
import {Mainsubtable} from './Mainsubtable'
import { Tableloading } from './Tableloading'


import {
    useTable,
    ColumnInstance,
    useSortBy,
    useExpanded,
    Row
  
  } from 'react-table'
type Props = {
    tabledata: any
    columndata: any
    tabledata2: any
    columndata2?: any
    hiddencolumnsmade: any
    hiddencolumnsmade2: any
    table1loaded: boolean | undefined
    table2loaded: boolean | undefined
    movetofirstpage : boolean | undefined
    tablename: string
    tablelength: number
    startlimit: number
    paginationsize: number
    pageCount: number
    setServersort: Dispatch<SetStateAction< any | undefined>>
    setPaginationsize: Dispatch<SetStateAction< any | undefined>>
    setPageCount:  Dispatch<SetStateAction< any | undefined>>
    setMovetofirstpage: Dispatch<SetStateAction< any | undefined>>
    filteredsubdata: (row: any) => void;
    paymenttypedetails?: (row: any) => void;
    filteredtablesubData : (row: any) => void;
    setstartlimit:  Dispatch<SetStateAction<any | undefined>>;
 
   
  }
  const API_URL = process.env.REACT_APP_API_URL

  const Maintable: React.FC<Props> = ({
  
    tabledata,
    columndata,
    tabledata2,
    columndata2,
    table1loaded,
    table2loaded,
    tablename,
    startlimit,
    hiddencolumnsmade,
    hiddencolumnsmade2,
    movetofirstpage,
    filteredsubdata,
    paymenttypedetails,
    filteredtablesubData,
    setstartlimit,
    tablelength,
    paginationsize,
    setPaginationsize,
    setMovetofirstpage,
    pageCount, 
    setPageCount,
    setServersort
  }) => {
    
   
   
  const pageSizes: number[] = [10, 20, 30, 40, 50]
  const [currentPage, setCurrentPage] = useState<number>(0);

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: {sortBy}, setHiddenColumns} = useTable(
        {
          columns: columndata as any,
          data: tabledata,
          manualSortBy: true,
          initialState: {
            hiddenColumns: hiddencolumnsmade
          }
         
         
        },
        useSortBy,
        useExpanded,
    
      )


      


      const handlePageClick = (event: any) => {
        
        const newOffset = ((event.selected) * paginationsize) % tablelength;

        setCurrentPage(event.selected)
        setstartlimit(newOffset)
       
        
      }


 

      useEffect(()=>{
        if(movetofirstpage){
         handlePageClick({ selected: 0 });
         setMovetofirstpage(false)
         }
      },[movetofirstpage])


      const handlePageSizeChange = (e: any) => {
     
        setPaginationsize(Number(e.target.value))
       
        setPageCount(Math.ceil(tablelength/Number(e.target.value)))

        handlePageClick({ selected: 0 });
        setMovetofirstpage(false)
      }


       useEffect(() => 
       { 

      
        
        if(tablelength > 0){
        handlePageClick({ selected: 0 });
         
        setPageCount(Math.ceil(tablelength/paginationsize))
        setMovetofirstpage(false)
        
         }else{
         

          setPageCount(0)
         }
       }, 
      
       [tablelength])

      useEffect(() => {
        setServersort(sortBy);

      }, [sortBy])
  


    return (
      <>
 
      <div className='table-responsive'>
            <table
              {...getTableProps()}
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                {headerGroups.map((headerGroup, keys) => (
                  <tr 
                    {...headerGroup.getHeaderGroupProps()}
                    className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                    key={keys + Date.now()}
                  >
                    {headerGroup.headers.map((column: any, key) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} key={key + Date.now()}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <>
                                <span className='svg-icon svg-icon-primary svg-icon-1x'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                  >
                                    <g fill='none' fillRule='evenodd'>
                                      <path d='M0 0L24 0 24 24 0 24z'></path>
                                      <path
                                        fill='#009ef7'
                                        fillRule='nonzero'
                                        d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </>
                            ) : (
                              <>
                                <span className='svg-icon svg-icon-primary svg-icon-1x'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                  >
                                    <g fill='none' fillRule='evenodd'>
                                      <path d='M0 0L24 0 24 24 0 24z'></path>
                                      <path
                                        fill='#009ef7'
                                        fillRule='nonzero'
                                        d='M6.707 15.707a1 1 0 11-1.414-1.414l6-6a1 1 0 011.383-.03l6 5.5a1 1 0 11-1.352 1.474l-5.294-4.853-5.323 5.323z'
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className='text-gray-600 fw-bold'>
                
                  <>
                    {rows.length > 0 ? (
                      rows.map((row : Row<any>, morekeys) => {
                        prepareRow(row)
                        return (
                          <React.Fragment key={row.getRowProps().key}>
                            <tr {...row.getRowProps()} key={morekeys + Date.now()}>

                            
                              {row.cells.map((cell : any, key2) => {
                                return (
                                  
                                    <td {...cell.getCellProps()} key={key2 + Date.now()}>{cell.render('Cell')}</td>
                                  
                                )
                              })}
                            </tr>
                            {row.isExpanded ? (
                           
                              <tr key={row.id + Date.now()}>
                                <td colSpan={columndata.length} className='m-10' >
                                  <div>
                              <Mainsubtable 
                               tabledata2={tabledata2} 
                               columndata2 ={columndata2} 
                               hiddencolumnsmade2 ={ hiddencolumnsmade2}
                               rowdata={table1loaded && row} 
                               tablename={tablename}
                               table1loaded={table1loaded}
                               table2loaded={table2loaded}
                               filteredsubdata ={filteredsubdata(row)}
                               paymenttypedetails={paymenttypedetails?.(row)}
                               filteredtablesubData={filteredtablesubData(row)}/> 
                               </div>
                               </td>
                              </tr>) : 
                              <>
                              
                             </>
                              }
                          </React.Fragment>
                        )
                      })
                    ) : (
                      table2loaded && table1loaded ? 
                      <></>:(
                      <tr>
                        <td colSpan={columndata.length}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>)
                    )}
                  </>
                  {table2loaded && table1loaded ? (
                  <>
                    <tr>
                      <td colSpan={columndata.length}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center m-3'>
                            <Tableloading />
                          </div>
                      </td>
                    </tr>
                  </>
                ):<></>}
              </tbody>
            </table>
          </div>
          <div className='row'>
  <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
    <div className='col-10 mb-2 mt-3' style={!table2loaded && !table1loaded?  {pointerEvents: 'auto', opacity: 1}: {pointerEvents: 'none', opacity: 0.5}}>
      <select
        onChange={handlePageSizeChange}
        className='form-select form-select-solid'
        aria-label='Select example'
        value={paginationsize}
        
      >
        {pageSizes.map((size, key3) => (
          <option key={key3 + Date.now()} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
  </div>

  <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
    <div className='mb-2' style={!table2loaded && !table1loaded?  {pointerEvents: 'auto', opacity: 1}: {pointerEvents: 'none', opacity: 0.5}}>
      <ReactPaginate
        breakLabel='...'
        nextLabel='>'
        onPageChange={handlePageClick}
        marginPagesDisplayed={tablename === 'Unpaid Summary' ? 1 : 1}
        pageRangeDisplayed={tablename === 'Unpaid Summary' ? 2 : 3}
        pageCount={pageCount}
        forcePage={pageCount > 0 ? currentPage : -1}
        previousLabel='<'
        containerClassName='pagination'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        activeClassName='page-item active'
        activeLinkClassName='page-item'
        previousClassName='page-item previous'
        previousLinkClassName='page-link'
        nextClassName='page-item next'
        nextLinkClassName='page-link'
        disabledLinkClassName='page-item previous disabled'
        disableInitialCallback={true}
        initialPage={currentPage - 1}

      />
    </div>
  </div>
</div>

         
      </>
    )
  }
  
  export {Maintable}