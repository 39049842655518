import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {KTSVG} from '../../../_metronic/helpers'
import {useUpdatebiodata, useUpdatebiodata2} from '../../contextProviders/Transactionsprovider'
import {useAuth} from '../../../app/modules/auth'
import {useBiodataContext} from '../../contextProviders/BiodataContext'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void
  activationdata: any
  allmemberdatarefetch: any
}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const Activatemembers: FC<Props> = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const itemstoupdate: any = {
    VAT_No: props.activationdata.VAT_No,
    Status: 'ACTIVE',
    Access_id: props.activationdata.Access_id
  }
  const {mutateAsync: updateBiodatamutateAsync} = useUpdatebiodata2(itemstoupdate)

  const activatemember: any = () => {
    setLoading(true)
    setError(null) // Reset error state before making the API call
    updateBiodatamutateAsync(itemstoupdate, {
      onSuccess: () => {
        setLoading(false)
        props.allmemberdatarefetch()
        props.handleClose()
      },
      onError: (error: any) => {
        console.error('API Error: ', error)
        setError('An error occurred while activating the account. Please try again.')
        setLoading(false)
      },
    })
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_clientsbrand_form'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder text-success'>Activate Account</h3>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
          {error ? (
            <div className='fs-6 alert alert-danger p-5 text-dark'>{error}</div>
          ) : (
            <div className='fs-6 alert alert-success p-5 text-dark'>
              Are you sure you want to activate the account belonging to{' '}
              <span className='fw-bold'>{props.activationdata.Business_Name}</span>? They have spent{' '}
              <span className='fw-bold text-primary'>
                {addCommas(Number(props.activationdata?.Total_Debit).toFixed(2))} {CURRENCY_APP}
              </span>{' '}
              this month. Additionally, they have paid golf club subscriptions totaling{' '}
              <span className='fw-bold text-info'>
                {addCommas(Number(props.activationdata?.Subscriptions_Total).toFixed(2))}{' '}
                {CURRENCY_APP}
              </span>{' '}
              this year.
            </div>
          )}
        </div>

        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-success' onClick={props.handleClose}>
            Close
          </button>
          <button
            type='submit'
            form='kt_modal_update_clientsbrand_form'
            className='btn btn-success'
            onClick={() => {
              activatemember()
            }}
          >
            {!loading && <span className='indicator-label'>Activate Account</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
})
