
import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'



import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { KTSVG } from '../../_metronic/helpers'
import { useUpdatebiodata } from '../contextProviders/Transactionsprovider'
import { Logout, useAuth } from '../../app/modules/auth'



dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void
  passworditemtoupdate: any

}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
export const Updatepassword: FC<Props> = forwardRef((props, ref) => {
  

  const [biodataadded, setBiodtaadded] = useState<any>({})
  const {currentUser, logout} = useAuth()
  const [loading, setLoading] = useState(false)

  const initialValues = {
    current_password:'',
    new_password: '',
    repeat_password: ''
  
  }

  const eventSchema = Yup.object().shape({
    current_password: Yup.string()
    .required('Current password is required')
    .test('password-match', 'Incorrect password', function(value) {
      return value === currentUser?.password;
    }),
  new_password: Yup.string()
    .min(5, 'Password must be at least 5 characters long')
    .required('New password is required'),
  repeat_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Please confirm your new password'),
  
  })
  

  const {mutateAsync: updateBiodatamutateAsync} = useUpdatebiodata(biodataadded)

  const formik: any = useFormik({
    initialValues: initialValues,

    validationSchema: eventSchema,

    onSubmit: async (values: any, {setStatus, setSubmitting, resetForm}) => {
      const updatePayload: any = {user_id: currentUser?.user_id, password: values.new_password};
    let isChanged = false; // Flag to track if there are any changes

     
     if (values.new_password !== props.passworditemtoupdate.password) {
      updatePayload.password = values.new_password;
      isChanged = true; // Mark as changed
    }

    // Proceed only if there are changes
    if (isChanged) {
     
      setBiodtaadded(updatePayload);
      setLoading(true); // Set loading true on change

      // Add your update logic here
    } else {
    
      setStatus("No changes to update.");
      setSubmitting(false);
    }
    },
  })
  useImperativeHandle(ref, () => ({
    Resetmodalvalues() {
      formik.resetForm({})
    
      setLoading(false)
    },
  }))
  
  useEffect(() => {
    if (Object.keys(biodataadded).length > 0) { // Check if there are changes
      updateBiodatamutateAsync(biodataadded, {
        onSuccess: () => {
          setLoading(false);
          formik.resetForm({});
         
          props.handleClose();
           logout()
        },
        onError: (error: any) => {
          console.error('API Error: ', error);
          formik.setStatus("There is an error updating the password.");
          setLoading(false);
        
          setBiodtaadded({});
        },
      });
    }
  }, [biodataadded]);

  

  return (
    <Modal
      className='modal fade'
      id='kt_modal_clientsbrand_form'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Update Password</h3>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
          {formik.status && (
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-center'>
                <span className='text-danger'>{formik.status}</span>
              </div>
            </div>
          )}
          <form
            id='kt_modal_update_clientsbrand_form'
            noValidate
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={formik.handleSubmit}
          >
            {/* Unit Retail Price Input Group */} 
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Current Password</label>
                {/* end::Label */}

                <input
                  type='password'
                  name='current_password' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('current_password')} // For touch feedback
                  value={formik.values.current_password} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.current_password && formik.errors.current_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.current_password}</span>
                    </div>
                  </div>
                )}
              </div>
            
            </div>

            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>New Password</label>
                {/* end::Label */}

                <input
                  type='password'
                  name='new_password' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('new_password')} // For touch feedback
                  value={formik.values.new_password} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.new_password && formik.errors.new_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.new_password}</span>
                    </div>
                  </div>
                )}
              </div>
            
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Repeat Password</label>
                {/* end::Label */}

                <input
                  type='password'
                  name='repeat_password' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('repeat_password')} // For touch feedback
                  value={formik.values.repeat_password} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.repeat_password && formik.errors.repeat_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.repeat_password}</span>
                    </div>
                  </div>
                )}
              </div>
            
            </div>
          </form>
        </div>

        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-primary' onClick={props.handleClose}>
            Cancel
          </button>
          <button
            type='submit'
            form='kt_modal_update_clientsbrand_form'
            className='btn btn-success'
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            {!loading && <span className='indicator-label'>Update Password</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
})
