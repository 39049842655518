import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/getuser`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(member_id: number|null, password: string) {


  return axios.post(LOGIN_URL, {
    member_id,
    password,
  })
}



export function getUserByToken(token: string) {
  
    return axios.post(GET_USER_BY_ACCESSTOKEN_URL,
    {
    api_token: token,
    })
}
